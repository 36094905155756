import {
  RumEvent,
  RumEventDomainContext,
  RumFetchResourceEventDomainContext,
  datadogRum,
} from '@datadog/browser-rum';
import { AuthContextResult } from '#app/contexts/AuthContext';
import { environment } from '#app/environment';

interface GraphQLRequest {
  operationName?: string;
  query: string;
}

export const datadogRumBeforeSend = (
  event: RumEvent,
  context: RumEventDomainContext
) => {
  // collect a RUM resource's request body
  if (event.type === 'resource' && event.resource.url.endsWith('/graphql')) {
    // send the GraphQL request body as a JSON object in the context.
    const requestBody = (context as RumFetchResourceEventDomainContext)
      .requestInit?.body;
    const parsedRequestBody: GraphQLRequest = requestBody
      ? JSON.parse(requestBody.toString())
      : {};
    const { query = '' } = parsedRequestBody;
    const operationName = parsedRequestBody.operationName ?? 'not-defined';
    const operationType =
      (parsedRequestBody.operationName && query
        ? query.substring(0, query.indexOf(' '))
        : undefined) ?? 'not-defined';
    /* Now in DD, you can reference 
        @context.operationName
        @context.operationType (query/mutation/not-defined)
        @context.requestBody
     */
    event.context = {
      ...event.context,
      requestBody: parsedRequestBody,
      operationName,
      operationType,
    };
  }
};

let enableRum = true;

// Disable DataDog RUM integration when running under Cypress.
if ((window as any).Cypress) {
  // Disable
  enableRum = false;
  // eslint-disable-next-line no-console
  console.warn('Disabled DataDog integration');
}

if (enableRum) {
  // Initialize DataDog RUM integration
  datadogRum.init({
    applicationId: 'd79e3711-2cf1-47d4-a3eb-1fabfd25ea92',
    clientToken: 'pubf3804177c4e11f9f7751fba5b868a47e',
    site: 'us3.datadoghq.com',
    service: `tn-web`,
    env: environment.DD_ENV,
    version: environment.DD_VERSION,
    sampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [/https:\/\/(?!login.*).*\.truenorthfleet\.com/],
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: datadogRumBeforeSend,
  });

  // enable session recording
  datadogRum.startSessionReplayRecording();
}

export const datadogSetUser = (authContext: AuthContextResult) => {
  if (!enableRum) {
    return;
  }

  if (!authContext.authenticated) {
    datadogRum.removeUser();
    return;
  }

  const { user } = authContext;
  const custom = { ...authContext.externalContext };

  // set the authenticated user with DataDog RUM
  datadogRum.setUser({
    id: user?.id.toString(),
    name: authContext.userFullName,
    email: authContext.userKey,
    ...custom,
  });
};

export const datadogLogError = (error: Error, context: any) => {
  if (!enableRum) {
    return;
  }

  datadogRum.addError(error, context);
};
