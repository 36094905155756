import { Link } from '@material-ui/core';

export interface Props {
  /**
   * The email's subject
   */
  subject?: string | undefined;
  /**
   * The to address
   * @todo possible refactor to be "to"
   */
  value?: string | null;
  /** What to display when no value. */
  empty?: React.ReactElement | null;
}

export function FormatAsEmailLink(props: Props) {
  const { subject, value, empty = null } = props;

  if (!value) {
    return empty;
  }

  const href = subject
    ? `mailto:${value}?subject=${encodeURIComponent(subject)}`
    : `mailto:${value}`;

  return (
    <Link href={href} target="_blank">
      {value}
    </Link>
  );
}

export default FormatAsEmailLink;
