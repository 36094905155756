import {
  ApolloClient,
  ApolloError,
  ApolloLink,
  FieldPolicy,
  FieldReadFunction, from, InMemoryCache, useApolloClient as useApolloClientOriginal
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { ErrorResponse, onError } from '@apollo/client/link/error';
import { relayStylePagination as createRelayStylePagination } from '@apollo/client/utilities';
import { createUploadLink } from 'apollo-upload-client';
import Cookies from 'js-cookie';
import { usePubNub } from 'pubnub-react';
import { useCallback } from 'react';
import { navigateToHomePage } from './api/auth';
import { CONNECTION_FIELDS, Query } from './graphql-codegen';
import { datadogLogError } from './utils/datadog';
import {
  RefreshableTypes, refreshEntities,
  refreshRootFields, RootQueryFields
} from './utils/liveUpdates';

type QueryFieldPolicies = {
  [P in keyof Query]?: FieldPolicy<any> | FieldReadFunction<any>;
};

const relayStylePagination = createRelayStylePagination((args) =>
  Object.keys(args || {}).filter((key) => key !== 'after' && key !== 'before')
);

export function createApolloClient() {
  /**
   * Only connection fields can support relay-style pagination.
   * https://relay.dev/graphql/connections.htm#sec-Connection-Types
   */
  const queryFieldPolicies: QueryFieldPolicies = {};
  for (const field of CONNECTION_FIELDS) {
    queryFieldPolicies[field] = relayStylePagination;
  }

  const errorLink = onError((errorResponse) => {
    if (isForbiddenError(errorResponse)) {
      // session is no longer authenticated, navigate to home page which should eventually redirect to login
      // necessary to support local dev.
      navigateToHomePage();
      return;
    }

    /*
    if (isBadGatewayError(errorResponse)) {
      // error will be reported elsewhere
      return;
    }
    */

    // log the error to DataDog RUM
    datadogLogError(new Error('GraphQL error occurred'), errorResponse);
  });

  const getCsrf = () => {
    const csrf = Cookies.get('csrftoken');
    return csrf;
  };

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'x-csrftoken': getCsrf(),
        authorization: `Bearer ${Cookies.get('access_token')}`,
      },
    };
  });

  const uploadLink = createUploadLink({
    uri: '/graphql',
    credentials: 'same-origin',
    headers: {
      'X-CSRFToken': getCsrf(),
    },
  }) as unknown as ApolloLink;

  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: queryFieldPolicies,
        },
      },
    }),
    link: from([errorLink, authLink, uploadLink]),
  });

  return client;
}

export interface ExtendedApolloClient extends ApolloClient<object> {
  /**
   * Efficiently refetches any active queries that reference the listed entities.
   */
  refreshEntities(
    entities: { __typename: RefreshableTypes; id: string | number }[]
  ): Promise<void>;

  /**
   * Returns a callback that efficiently refetches any active queries that reference the listed
   * entities.
   *
   * The callback form is provided to make Promise chaining eaiser. For example:
   *
   * regenerateInvoices([invoiceLoad.id])
   *   .then(client.refreshEntitiesCallback([invoiceLoad]))
   *   .then((response) => console.log('success', response));
   */
  refreshEntitiesCallback<TResult>(
    entities: { __typename: RefreshableTypes; id: string | number }[]
  ): (result: TResult) => Promise<TResult>;

  /**
   * Refreshes any active queries that reference {@link rootFields}. Inactive queries that have cached
   * data are invalidated so that the cache is skipped the next time they are invoked.
   */
  refreshRootFields(rootFields: RootQueryFields[]): Promise<void>;
}

/**
 * TN ApolloClient hook.
 * @returns
 */
export function useApolloClient(): ExtendedApolloClient {
  const client = useApolloClientOriginal() as ExtendedApolloClient;
  const pubnub = usePubNub();

  client.refreshEntities = useCallback(
    (entities) => refreshEntities(client, pubnub, entities),
    [client, pubnub]
  );
  client.refreshEntitiesCallback = useCallback(
    (entities) => (result) =>
      refreshEntities(client, pubnub, entities).then((_) => result),
    [client, pubnub]
  );
  client.refreshRootFields = useCallback(
    (rootFields) => refreshRootFields(client, pubnub, rootFields),
    [client, pubnub]
  );
  return client;
}

/**
 * Determine if the GraphQL error represents 403 Forbidden.
 * @param errorResponse
 * @returns Whether access was forbidden
 */
export const isForbiddenError = (errorResponse: ErrorResponse) => {
  // the statusCode is returned in the networkError, but not in the type defintion
  const statusCode = (errorResponse.networkError as any)?.statusCode;

  return statusCode === 403;
};

/**
 * Determine if the GraphQL error represents 502 Bad Gateway.
 * @param errorResponse
 * @returns Whether this is the specified error.
 */
export const isBadGatewayError = (
  errorResponse: ErrorResponse | ApolloError
) => {
  // the statusCode is returned in the networkError, but not in the type defintion
  const statusCode = (errorResponse.networkError as any)?.statusCode;

  return statusCode === 502;
};

export enum ErrorReasonCode {
  NoResponse = 'no-response',
  Exception = 'exception',
}

export enum ErrorDisplayLocation {
  Toast = 'toast',
  ErrorScreen = 'errorScreen',
}

export interface UserFacingError {
  reasonCode: ErrorReasonCode;
  message: string;
  displayLocation: ErrorDisplayLocation;
  exception?: ErrorResponse | ApolloError | Error;
}

export const getUserFacingError = (
  errorResponse: ErrorResponse | ApolloError
) => {
  let userError: UserFacingError = {
    reasonCode: ErrorReasonCode.Exception,
    exception: errorResponse,
    displayLocation: ErrorDisplayLocation.Toast,
    message:
      (errorResponse as any).message ?? 'An unspecified error has occurred.',
  };

  if (isBadGatewayError(errorResponse)) {
    userError.reasonCode = ErrorReasonCode.NoResponse;
    userError.message = 'Unable to process your request at this time.';
  }

  return userError;
};
