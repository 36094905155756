import { ApolloProvider } from '@apollo/client';
import { useAuth0 } from '@auth0/auth0-react';
import { init } from '@fullstory/browser';
import Cookies from 'js-cookie';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { SnackbarProvider } from 'notistack';
import { Suspense, lazy, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from 'react-router-dom';
import { FeatureFlagMap } from '#app/@types/FeatureFlags';
import { CurrentUser } from '#app/currentUser';
import ErrorBoundary from './ErrorBoundary';
import MobileProtectedRoute from './MobileProtectedRoute';
import ProtectedRoute from './ProtectedRoute';
import ScrollToTop from './ScrollToTop';
import { getApiAuthenticatedUser } from './api/auth';
import { createApolloClient } from './apolloClient';
import {
  AuthContext,
  AuthContextResult,
  AuthUserType,
  InitializingAuthContext,
  getAuthContextForUser,
  useAuthContext,
} from './contexts/AuthContext/AuthContext';
import { AppEnv, environment } from './environment';
import { fullstorySetUser } from './fullstory';
import Loading from './layouts/Loading/Loading';
import { AccountPending } from './pages/AccountPending/AccountPending';
import { ReferenceInformation } from './pages/ReferenceInformation/ReferenceInformation';
import { TnPubNub } from './pubnub';
import { MuiFifthWheelThemeProvider } from './themes/MuiFifthWheelThemeProvider';
import { datadogSetUser } from './utils/datadog';
import { setLDIdentifyUserFromContext } from './utils/launchDarkly';

const CarrierRelationshipPage = lazy(
  () => import('./modules/customers/pages/CarrierRelationshipPage')
);
const ContactUsPage = lazy(() => import('./pages/ContactUsPage'));
const CustomerCreatePage = lazy(() => import('./pages/CustomerAddPage'));
const CustomerDetailsPage = lazy(() => import('./pages/CustomerDetailsPage'));
const CustomersPage = lazy(() => import('./pages/CustomersPage'));
const DeviceAddPage = lazy(() => import('./pages/DeviceAddPage'));
const DeviceDetailsPage = lazy(() => import('./pages/DeviceDetailsPage'));
const DevicesPage = lazy(() => import('./pages/DevicesPage'));
const DocumentsPage = lazy(() => import('./pages/DocumentsPage/DocumentsPage'));
const CompanyUserAddPage = lazy(
  () => import('./pages/CompanyUserAddPage/CompanyUserAddPage')
);
const CompanyUserDetailsPage = lazy(
  () => import('./pages/CompanyUserDetailsPage/CompanyUserDetailsPage')
);
const FleetPage = lazy(() => import('./pages/FleetPage'));
const HealthPage = lazy(() => import('./pages/HealthPage'));
const CompanyAddPage = lazy(
  () => import('./pages/CompanyAddPage/CompanyAddPage')
);
const BrokerRelationshipPage = lazy(
  () => import('./modules/companies/pages/BrokerRelationshipPage')
);
const CompanyDetailsPage = lazy(
  () => import('./pages/CompanyDetailsPage/CompanyDetailsPage')
);
const CompaniesPage = lazy(() => import('./pages/CompaniesPage/CompaniesPage'));
const LoadCentralDesktopPage = lazy(
  () => import('./modules/load-central/pages/LoadCentralDesktopPage')
);
const LoadCreatePage = lazy(() => import('./pages/LoadCreatePage'));
const LoadDetailsPage = lazy(() => import('./pages/LoadDetailsPage'));
const LoadsPage = lazy(() => import('./pages/LoadsPage/PageLayout'));
const LoadCentralMobilePage = lazy(
  () => import('./modules/load-central/pages/LoadCentralMobilePage')
);
const MyLoadsPage = lazy(() => import('./modules/ico-view/pages/MyLoadsPage'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));
const MyProfilePage = lazy(() => import('./pages/MyProfilePage'));
const SettlementsPage = lazy(() => import('./pages/SettlementsPage'));
const PendingPaymentsPage = lazy(
  () => import('./pages/PendingPaymentsPage/PendingPaymentsPage')
);
const SettlementsDetailPage = lazy(
  () => import('./pages/SettlementsDetailPage')
);
const TestDocumentUploadDialog = lazy(
  () => import('./pages/TestDocumentUploadDialogPage')
);
const TractorsPage = lazy(() => import('./pages/TractorsPage'));
const TractorAddPage = lazy(
  () => import('./pages/TractorAddPage/TractorAddPage')
);
const TractorDetailsPage = lazy(
  () => import('./pages/TractorDetailsPage/TractorDetailsPage')
);
const TrailerAddPage = lazy(() => import('./pages/TrailerAddPage'));
const TrailerDetailsPage = lazy(() => import('./pages/TrailerDetailsPage'));
const TrailersPage = lazy(() => import('./pages/TrailersPage'));
const UploadPage = lazy(() => import('./pages/UploadPage'));
const NoAccessPage = lazy(() => import('./pages/NoAccessPage/NoAccessPage'));
const ServerDownPage = lazy(
  () => import('./pages/ServerDownPage/ServerDownPage')
);

const client = createApolloClient();

interface DecodedToken {
  exp: number;
}

// initialize FullStory
init({ orgId: '14PRCC', devMode: environment.APP_ENV !== AppEnv.PROD });

export const RedirectToDefaultPage: React.VFC = () => {
  const authContext = useAuthContext();

  if (authContext.authenticated) {
    if (authContext.userType === AuthUserType.Staff) {
      return <Redirect to="/loadcentral" />;
    }

    return <Redirect to="/my/settlements" />;
  }

  return <Loading />;
};

export const App: React.FC = () => {
  // authentication state
  const [authState, setAuthState] = useState<AuthContextResult>(
    InitializingAuthContext
  );
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  // launchdarkly
  const ldClient = useLDClient();
  const { showPendingPaymentsPage } = useFlags<FeatureFlagMap>();
  const processAuthentication = async () => {
    const response = await getApiAuthenticatedUser();
    const { data: user }: { data: CurrentUser } = response;
    if (user) {
      user.is_authenticated = true;
    }
    const authUser = getAuthContextForUser(user);
    setAuthState(authUser);
    (window as any).user = user;
  };

  useEffect(() => {
    if (isAuthenticated) {
      getAccessTokenSilently().then((token: string) => {
        Cookies.set('access_token', token);
        processAuthentication();
      });
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  useEffect(() => {
    if (authState.initializing) {
      if (ldClient) {
        setLDIdentifyUserFromContext(authState, ldClient);
      }

      // skip remainder of logic
      return;
    }

    if (!authState.authenticated) {
      // eslint-disable-next-line no-console
      console.log('Not authenticated:', { authState });

      datadogSetUser(authState);

      if (ldClient) {
        setLDIdentifyUserFromContext(authState, ldClient);
      }

      return;
    }

    // eslint-disable-next-line no-console
    console.log('Authenticated: ', { authState });

    // set fullstory user
    fullstorySetUser(authState);

    // set datadog user
    datadogSetUser(authState);

    // update launch darkly
    if (ldClient) {
      setLDIdentifyUserFromContext(authState, ldClient);
    }
  }, [ldClient, authState]);

  return (
    <MuiFifthWheelThemeProvider>
      <ApolloProvider client={client}>
        <AuthContext.Provider value={authState}>
          <SnackbarProvider maxSnack={3}>
            <ErrorBoundary>
              <TnPubNub>
                <Router>
                  <ScrollToTop />
                  <Suspense fallback={<Loading />}>
                    <Switch>
                      {/* Routes must be sorted in reverse-alphabetical order */}

                      {/* Upload */}
                      <ProtectedRoute
                        exact
                        path="/upload"
                        component={UploadPage}
                      />

                      {/* Trailers */}
                      <ProtectedRoute
                        exact
                        path="/trailers/add"
                        component={TrailerAddPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/trailers/:id"
                        component={TrailerDetailsPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/trailers"
                        component={TrailersPage}
                      />

                      {/* Tractors */}
                      <ProtectedRoute
                        exact
                        path="/tractors"
                        component={TractorsPage}
                      />

                      {/* Tests */}
                      <ProtectedRoute
                        exact
                        path="/test-document-upload-dialog"
                        component={TestDocumentUploadDialog}
                      />

                      {/* Settle by ICO */}
                      <ProtectedRoute
                        exact
                        path={[
                          '/settlements/:icoId',
                          '/settlements/:icoId/settlement',
                          '/settlements/:icoId/settlement/:settlementId',
                          '/settlements/:icoId/deductions',
                          '/settlements/:icoId/deductions/:userId',
                        ]}
                        component={SettlementsDetailPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/settlements"
                        component={SettlementsPage}
                      />

                      {showPendingPaymentsPage && (
                        <ProtectedRoute
                          exact
                          path="/pending-payments"
                          component={PendingPaymentsPage}
                        />
                      )}

                      {/* Reference Information */}
                      <ProtectedRoute
                        exact
                        path="/reference"
                        component={ReferenceInformation}
                      />

                      {/* Profile */}
                      <ProtectedRoute
                        exact
                        path={['/my/profile', '/user/profile']}
                        component={MyProfilePage}
                      />

                      {/* Redirect from="/my/loads" to="/my/settlements"  */}

                      <ProtectedRoute
                        path="/my/loads"
                        component={(props: any) => {
                          return (
                            <Redirect
                              to={{
                                search: props.location.search,
                                pathname: props.location.pathname.replace(
                                  'loads',
                                  'settlements'
                                ),
                              }}
                            />
                          );
                        }}
                      />

                      {/* Settlements */}
                      <ProtectedRoute
                        exact
                        path="/my/settlements"
                        component={MyLoadsPage}
                      />

                      {/* LoadCentral */}
                      <MobileProtectedRoute
                        exact
                        path="/mobile/loadcentral"
                        component={LoadCentralMobilePage}
                      />
                      <ProtectedRoute
                        exact
                        path="/loadcentral"
                        component={LoadCentralDesktopPage}
                      />

                      {/* Loads */}
                      <ProtectedRoute
                        exact
                        path="/loads/create"
                        component={LoadCreatePage}
                      />
                      <ProtectedRoute
                        exact
                        path="/loads/:id"
                        component={LoadDetailsPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/loads"
                        component={LoadsPage}
                      />

                      {/* Contact Us */}
                      <ProtectedRoute
                        exact
                        path="/contactus"
                        component={ContactUsPage}
                      />

                      {/* Companies */}
                      <ProtectedRoute
                        exact
                        path="/companies/add"
                        component={CompanyAddPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/companies/:companyId/add"
                        component={CompanyAddPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/companies/:companyId/tractors/add"
                        component={TractorAddPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/companies/:companyId/trailers/add"
                        component={TrailerAddPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/companies/:companyId/tractors/:tractorId"
                        component={TractorDetailsPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/companies/:companyId/team/add"
                        component={CompanyUserAddPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/companies/:companyId/team/:userId"
                        component={CompanyUserDetailsPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/companies/:companyId/broker/:relationshipId"
                        component={BrokerRelationshipPage}
                      />
                      <ProtectedRoute
                        exact
                        path={[
                          '/companies/:companyId',
                          '/companies/:companyId/:tabName',
                        ]}
                        component={CompanyDetailsPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/companies"
                        component={CompaniesPage}
                      />

                      {/* Health */}
                      <ProtectedRoute
                        exact
                        path="/health"
                        component={HealthPage}
                      />

                      {/* Fleet */}
                      <ProtectedRoute
                        exact
                        path="/fleet"
                        component={FleetPage}
                      />

                      {/* Documents */}
                      <ProtectedRoute
                        exact
                        path="/documents"
                        component={DocumentsPage}
                      />

                      {/* Devices */}
                      <ProtectedRoute
                        exact
                        path="/devices/add"
                        component={DeviceAddPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/devices/:deviceId"
                        component={DeviceDetailsPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/devices"
                        component={DevicesPage}
                      />

                      {/* Customers */}
                      <ProtectedRoute
                        exact
                        path="/customers/add"
                        component={CustomerCreatePage}
                      />
                      <ProtectedRoute
                        exact
                        path={[
                          '/customers/:billCustomerId',
                          '/customers/:billCustomerId/:tabName',
                        ]}
                        component={CustomerDetailsPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/customers/:billCustomerId/carrier/:relationshipId"
                        component={CarrierRelationshipPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/customers"
                        component={CustomersPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/noaccess"
                        component={NoAccessPage}
                      />
                      <ProtectedRoute
                        exact
                        path="/server_down"
                        component={ServerDownPage}
                      />

                      {/* Account Pending */}
                      <Route
                        exact
                        path={['/account/pending', '/acc/pending']}
                        component={AccountPending}
                      />

                      {/* Home page (which depends on the user type) */}
                      <ProtectedRoute
                        exact
                        path="/"
                        component={RedirectToDefaultPage}
                      />

                      {/* If all else fails, show that no match could be returned */}
                      <ProtectedRoute component={NotFoundPage} />
                    </Switch>
                  </Suspense>
                </Router>
              </TnPubNub>
            </ErrorBoundary>
          </SnackbarProvider>
        </AuthContext.Provider>
      </ApolloProvider>
    </MuiFifthWheelThemeProvider>
  );
};

export default App;
