import { Auth0Provider, AuthorizationParams } from '@auth0/auth0-react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import React from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { AppEnv, environment } from '#app/environment';
import { App } from './App';
import reportWebVitals from './reportWebVitals';
import {
  DefaultFlags,
  activeContext,
  getLDOptionsFromAuthContext,
} from './utils/launchDarkly';

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: environment.LAUNCHDARKLY_CLIENT_ID,
    user: getLDOptionsFromAuthContext(activeContext),
    options: {
      // Implement default feature flags
      bootstrap: DefaultFlags,
    },
  });

  ReactDOM.render(
    <React.StrictMode>
      <HelmetProvider>
        <LDProvider>
          <Auth0Provider
            domain={environment.AUTH0_DOMAIN}
            clientId={environment.AUTH0_CLIENT_ID}
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: environment.API_IDENTIFIER,
            }}>
            <App />
          </Auth0Provider>
        </LDProvider>
      </HelmetProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
