import { Children } from '#app/@types/Children';
import { RootLayout } from '#app/layouts/RootLayout';

export interface Props {
  children: Children;

  /**
   * The title that appears in the browser tab
   */
  title: string;
}

export function UnauthenticatedLayout(props: Props) {
  const { children, title } = props;

  return <RootLayout title={title}>{children}</RootLayout>;
}

export default UnauthenticatedLayout;
