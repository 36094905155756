import { useApolloClient } from '@apollo/client';
import { SnackbarKey, useSnackbar } from 'notistack';
import Pubnub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';
import { useEffect, useState } from 'react';
import { Children } from './@types/Children';
import { useAuthContext } from './contexts/AuthContext';
import { environment } from './environment';
import { initializeLiveUpdates } from './utils/liveUpdates';

const ChannelPrefix = `${environment.PUBNUB_CHANNEL_PREFIX ?? ''}`;

/**
 * Channels which can be subscribed to.
 */
export const Channel = {
  settlementProgress: `${ChannelPrefix}settlement.progress`,
  entityUpdate: `${ChannelPrefix}entity.update`,
  refreshRootFields: `${ChannelPrefix}refresh.rootFields`,
};

export interface EntityUpdateMessage {
  // Entity id.
  id: string;

  // Entity type.
  type: string;

  // Entity update timestamp.
  updatedAt?: string;

  // Indicates how many updates were sent in this batch.
  batchSize: number;
}

export interface RefreshRootFieldsMessage {
  rootFields: string[];
}

/**
 * Returns a filter expression that matches {@link entities}.
 * https://www.pubnub.com/docs/messages/publish#filter-language-definition
 */
export function filterExpressionForEntities(
  entities: ReadonlyMap<string, ReadonlySet<string>>
): string {
  const expressions: string[] = [];
  for (const type of entities.keys()) {
    const ids = Array.from(entities.get(type)!)
      .map((id) => `'${id}'`)
      .join(',');
    expressions.push(`(type == '${type}' && ((${ids}) CONTAINS id))`);
  }
  return expressions.join(' || ');
}

export interface AuthenticatedPubNubProps {
  userId: string;
  children: Children;
}

export const TnAuthenticatedPubNub: React.FC<AuthenticatedPubNubProps> = ({
  userId,
  children,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const apolloClient = useApolloClient();
  const [pubnub] = useState<Pubnub>(
    new Pubnub({
      publishKey: environment.PUBNUB_PUBLISH_KEY,
      subscribeKey: environment.PUBNUB_SUBSCRIBE_KEY,
      uuid: userId,
      autoNetworkDetection: true,
      restore: true,
      ssl: true,
    })
  );

  useEffect(() => {
    let key: SnackbarKey | undefined;
    const listener: Pubnub.ListenerParameters = {
      status(e) {
        switch (e.category) {
          case Pubnub.CATEGORIES.PNNetworkDownCategory:
            if (!key) {
              key = enqueueSnackbar(
                'Network connection lost, data may be stale.',
                {
                  variant: 'error',
                  persist: true,
                }
              );
            }
            break;
          case Pubnub.CATEGORIES.PNNetworkUpCategory:
            if (key) {
              closeSnackbar(key);
              key = undefined;
            }
            break;
        }
      },
    };

    pubnub.addListener(listener);

    // initialize live updates globally
    initializeLiveUpdates(pubnub, apolloClient);

    // eslint-disable-next-line no-console
    console.log('pubnub: initialized');
  }, [pubnub, apolloClient, enqueueSnackbar, closeSnackbar]);

  return <PubNubProvider client={pubnub}>{children}</PubNubProvider>;
};

export interface TnPubNubProps {}

export const TnPubNub: React.FC<TnPubNubProps> = ({ children }) => {
  const authContext = useAuthContext();

  if (authContext.initializing || !authContext.authenticated) {
    return <>{children}</>;
  }

  return (
    <TnAuthenticatedPubNub userId={`${authContext.user?.id!}`}>
      {children}
    </TnAuthenticatedPubNub>
  );
};

export enum BatchStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export interface BatchInfo {
  companyId: string;
  status: 'success' | 'error';
  message: string;
}

export interface LiveStatusUpdate {
  username: string;
  succeeded: number;
  failed: number;
  total: number;
  timestamp: string;
  batchId: string;
  batchStatus: BatchStatus;
  batchInfo: BatchInfo[];
}

export function useStreamPubnubData() {
  const pubnub = usePubNub();
  const [settlementsLiveStatusUpdate, setSettlementsLiveStatusUpdate] =
    useState<LiveStatusUpdate[]>([]);
  const [recentPubnubMessage, setRecentPubnubMessage] =
    useState<LiveStatusUpdate>();

  useEffect(() => {
    const listener: Pubnub.ListenerParameters = {
      message(e) {
        if (
          e.channel === Channel.settlementProgress &&
          e.message?.type === 'SettlementProgress'
        ) {
          const batchId = e.message.batchId;
          const filteredItems = settlementsLiveStatusUpdate.filter(
            (x) => x.batchId !== batchId
          );

          filteredItems.push(e.message);
          setSettlementsLiveStatusUpdate(filteredItems);
          setRecentPubnubMessage(e.message);
        }
      },
    };

    pubnub.addListener(listener);

    // teardown
    return () => {
      pubnub.removeListener(listener);
    };
  }, [pubnub, settlementsLiveStatusUpdate]);
  return { settlementsLiveStatusUpdate, recentPubnubMessage };
}
