import Cookies from 'js-cookie';
import { ComponentType, FC } from 'react';
import { Route } from 'react-router-dom';
import NoAccessPage from './pages/NoAccessPage/NoAccessPage';

const mobileAuthenticationRequired = <P extends object>(
  Component: ComponentType<P>
): FC<P> => {
  return function WithMobileAuthenticationRequired(props: P): JSX.Element {
    const hasToken = Boolean(Cookies.get('access_token'));
    return hasToken ? <Component {...props} /> : NoAccessPage();
  };
};

// @ts-ignore
const MobileProtectedRoute = ({ component, ...args }) => (
  <Route component={mobileAuthenticationRequired(component)} {...args} />
);

export default MobileProtectedRoute;
