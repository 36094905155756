import { useScrollTrigger } from '@material-ui/core';
import { cloneElement, ReactElement } from 'react';

export interface Props {
  children: ReactElement;
}

export function ElevationScroll(props: Props) {
  const { children } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

export default ElevationScroll;
