/**
 * @file Provides functions to register and compute queries of "active" entities.
 * An "entity" just refers to a GraphQL object that has an id field. An "active"
 * entity refers to an entity that is currently being used in a React component.
 *
 * This functionality is used to derive a GraphQL query for an active entity that
 * has changed on the backend so we can update the Apollo client with the latest
 * data.
 *
 * This infra is integrated with Apollo hooks, so application engineers don't need
 * to interact with this code.
 */
import { WatchQueryFetchPolicy } from '@apollo/client';
import {
  ArgumentNode,
  BooleanValueNode,
  DirectiveNode,
  DocumentNode,
  EnumValueNode,
  FieldNode,
  FloatValueNode,
  FragmentDefinitionNode,
  FragmentSpreadNode,
  InlineFragmentNode,
  IntValueNode,
  ListValueNode,
  NameNode,
  ObjectFieldNode,
  ObjectValueNode,
  OperationDefinitionNode,
  SelectionNode,
  SelectionSetNode,
  StringValueNode,
  ValueNode,
  VariableNode,
} from 'graphql';
import { EntitySetObserver, ObservableEntitySet } from './ObservableEntitySet';
import { DisposeFunc, EntityId, EntityType } from './types';

export type TypeMap = {
  [type: string]: { [field: string]: string | undefined } | undefined;
};

/**
 * Represents the {@link SelectionSetNode} for a given entity.
 */
interface EntitySelectionSet {
  readonly type: EntityType;
  readonly id: EntityId;
  readonly selectionSet: SelectionSetNode;
}

/**
 * Represents and entity which is returned by GraphQL.
 */
interface GraphQLEntity {
  id: string | number;
  __typename: string;
}

/**
 * A custom set type that keeps track of duplicate items so that an item must be
 * removed the same number of times it is added to be fully removed from the set.
 */
class CountingSet<T> {
  private readonly counts = new Map<T, number>();
  private items = new Set<T>();

  get size(): number {
    return this.items.size;
  }

  has(item: T): boolean {
    return this.items.has(item);
  }

  add(item: T) {
    this.items.add(item);
    this.counts.set(item, (this.counts.get(item) || 0) + 1);
  }

  remove(item: T) {
    const count = this.counts.get(item);
    if (count === 1) {
      this.items.delete(item);
    }
    if (count) {
      this.counts.set(item, count - 1);
    }
  }

  toArray(): T[] {
    return Array.from(this.items);
  }
}

// The selection sets of all entities which are currently registered.
const renderedSelectionSets = new Map<
  EntityType,
  Map<EntityId, CountingSet<SelectionSetNode>>
>();

// Weak maps for memoization.
const entitySelectionSetsFromSelectionSetCache: WeakMap<
  SelectionSetNode,
  WeakMap<any, EntitySelectionSet[]>
> = new WeakMap();
const flattenDocumentCache: WeakMap<DocumentNode, DocumentNode> = new WeakMap();
const annotateQueryWithIdsCache: WeakMap<DocumentNode, DocumentNode> =
  new WeakMap();

const renderedEntities = new ObservableEntitySet();
const cachedEntities = new ObservableEntitySet();

/**
 * Be notified whenever the set of rendered entities changes.
 */
export function observeRenderedEntities(
  observer: EntitySetObserver
): DisposeFunc {
  return renderedEntities.observe(observer);
}

/**
 * Be notified whenever the set of cached entities changes.
 */
export function observeCachedEntities(
  observer: EntitySetObserver
): DisposeFunc {
  return cachedEntities.observe(observer);
}

export function evictCachedEntity(type: EntityType, id: EntityId) {
  cachedEntities.remove(type, id);
}

/**
 * Parses the query data and registers the fields queried for each entity.
 *
 * This allows the fields to be collected later by calling {@link queryForRegisteredEntity}.
 */
export function registerEntitiesFromQuery(
  query: DocumentNode,
  data: any,
  fetchPolicy: WatchQueryFetchPolicy
): DisposeFunc {
  if (!data) {
    return () => {};
  }
  const entitySelectionSets = entitySelectionSetsFromQueryResult(query, data);
  return registerEntitySelectionSetsFromFetch(entitySelectionSets, fetchPolicy);
}

export function isEntityRendered(type: EntityType, id: EntityId): boolean {
  return (renderedSelectionSets.get(type)?.get(id)?.size || 0) > 0;
}

/**
 * Collects all queried fields for an entity into a single query.
 *
 * If the entity is not registered, null is returned.
 */
export function collectQueryForRenderedEntities(
  params: Array<
    | {
        id: string;
        type: string;
        entityLookupFields: string[];
        entityBulkLookupFields?: undefined;
      }
    | {
        id: string;
        type: string;
        entityLookupFields?: undefined;
        entityBulkLookupFields: string[];
      }
  >
): DocumentNode | null {
  const entities = params
    .map((p) => ({
      ...p,
      selectionSets:
        renderedSelectionSets.get(p.type)?.get(p.id)?.toArray() || [],
    }))
    .filter((e) => e.selectionSets.length > 0);

  if (!entities.length) return null;

  // When connection fields are used, the intent is to update a large number of entities.
  // When computing the query, it's more optimal to query each selectionSet separately
  // rather than merging into a single selectionSet. For example, a table may show 2 fields
  // for all entities of a given type, whereas a detail view may show many fields for one
  // entity of the same type. We wouldn't want to fetch all fields for all entities since
  // the data isn't required, and it will impact latency.
  const entityIdsBySelectionSet = new Map<SelectionSetNode, Set<EntityId>>();
  const connectionFieldSelectionSet = new Map<SelectionSetNode, string[]>();
  for (const entity of entities) {
    if (!entity.entityBulkLookupFields) continue;

    for (const selectionSet of entity.selectionSets) {
      if (!entityIdsBySelectionSet.has(selectionSet)) {
        entityIdsBySelectionSet.set(selectionSet, new Set());
        connectionFieldSelectionSet.set(
          selectionSet,
          entity.entityBulkLookupFields
        );
      }
      entityIdsBySelectionSet.get(selectionSet)!.add(entity.id);
    }
  }

  const connectionSelections: SelectionNode[] = Array.from(
    entityIdsBySelectionSet.entries()
  )
    .map(([selectionSet, ids], index) => {
      const connectionFields = connectionFieldSelectionSet.get(selectionSet)!;
      const idIn: StringValueNode[] = Array.from(ids).map((id) => ({
        kind: 'StringValue',
        value: id,
      }));
      const selections = mergeSelections(selectionSet.selections);
      return connectionFields.map((connectionField) => {
        const node: SelectionNode = {
          kind: 'Field',
          name: {
            kind: 'Name',
            value: connectionField,
          },
          alias: {
            kind: 'Name',
            value: `${connectionField}_${index}`,
          },
          arguments: [
            {
              kind: 'Argument',
              name: {
                kind: 'Name',
                value: 'idIn',
              },
              value: {
                kind: 'ListValue',
                values: idIn,
              },
            },
          ],
          selectionSet: {
            kind: 'SelectionSet',
            selections: [
              {
                kind: 'Field',
                name: {
                  kind: 'Name',
                  value: 'edges',
                },
                selectionSet: {
                  kind: 'SelectionSet',
                  selections: [
                    {
                      kind: 'Field',
                      name: {
                        kind: 'Name',
                        value: 'node',
                      },
                      selectionSet: {
                        kind: 'SelectionSet',
                        selections,
                      },
                    },
                  ],
                },
              },
            ],
          },
        };
        return node;
      });
    })
    .flat();

  const entitySelections: SelectionNode[] = entities
    .filter((s) => !!s.entityLookupFields)
    .map(({ id, entityLookupFields, selectionSets }, index) => {
      const selections = mergeSelections(
        selectionsFromSelectionSets(selectionSets)
      );
      const nodes = entityLookupFields!.map((entityLookupField) => {
        const node: SelectionNode = {
          kind: 'Field',
          name: {
            kind: 'Name',
            value: entityLookupField!,
          },
          alias: {
            kind: 'Name',
            value: `${entityLookupField}_${index}`,
          },
          arguments: [
            {
              kind: 'Argument',
              name: {
                kind: 'Name',
                value: 'id',
              },
              value: {
                kind: 'StringValue',
                value: id,
              },
            },
          ],
          selectionSet: {
            kind: 'SelectionSet',
            selections,
          },
        };
        return node;
      });
      return nodes;
    })
    .flat();

  return queryFromSelections([...entitySelections, ...connectionSelections]);
}

export function queryFromSelections(selections: SelectionNode[]): DocumentNode {
  return {
    kind: 'Document',
    definitions: [
      {
        kind: 'OperationDefinition',
        operation: 'query',
        name: { kind: 'Name', value: 'liveUpdate' } as NameNode,
        selectionSet: {
          kind: 'SelectionSet',
          selections,
        },
      } as OperationDefinitionNode,
    ],
  };
}

/**
 * Enures id fields are always selected where they exist.
 */
export function annotateQueryWithIds<T extends DocumentNode>(
  query: T,
  typeMap: TypeMap
): T {
  if (!annotateQueryWithIdsCache.has(query)) {
    const definitions = query.definitions.map((def) => {
      if (
        def.kind === 'OperationDefinition' &&
        (def.operation === 'query' || def.operation === 'mutation')
      ) {
        return {
          ...def,
          selectionSet: annotateSelectionSetWithIds(
            def.selectionSet,
            def.operation === 'query' ? 'Query' : 'Mutations',
            typeMap
          ),
        };
      } else if (def.kind === 'FragmentDefinition') {
        return {
          ...def,
          selectionSet: annotateSelectionSetWithIds(
            def.selectionSet,
            def.typeCondition.name.value,
            typeMap
          ),
        };
      }
      return def;
    });
    annotateQueryWithIdsCache.set(query, { ...query, definitions });
  }
  return annotateQueryWithIdsCache.get(query) as T;
}

/**
 * Returns a list of all rendered entities.
 */
export function collectRenderedEntities(): {
  type: EntityType;
  id: EntityId;
}[] {
  return renderedEntities.asList();
}

/**
 * Returns a list of all cached entities.
 */
export function collectCachedEntities(): { type: EntityType; id: EntityId }[] {
  return cachedEntities.asList();
}

/**
 * Returns a map of all rendered entities.
 */
export function collectRenderedEntitiesByType(): ReadonlyMap<
  EntityType,
  ReadonlySet<EntityId>
> {
  return renderedEntities.byType();
}

/**
 * Returns a map of all cached entities.
 */
export function collectCachedEntitiesByType(): ReadonlyMap<
  EntityType,
  ReadonlySet<EntityId>
> {
  return cachedEntities.byType();
}

/**
 * Returns true if {@link value} is an object.
 */
function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null;
}

/**
 * Returns true if {@link value} has the shape of {@link GraphQLEntity}.
 */
function isEntity(value: any): boolean {
  return isObject(value) && value.id && value.__typename;
}

/**
 * Returns an unregistration function.
 */
function registerEntitySelectionSetsFromFetch(
  entitySelectionSets: EntitySelectionSet[],
  fetchPolicy: WatchQueryFetchPolicy
): DisposeFunc {
  let unregister: Array<() => void> = [];

  for (const entitySelectionSet of entitySelectionSets) {
    const { type, id, selectionSet } = entitySelectionSet;
    if (!renderedSelectionSets.has(type)) {
      renderedSelectionSets.set(type, new Map());
    }
    const selectionSetsForType = renderedSelectionSets.get(type)!;
    if (!selectionSetsForType.has(id)) {
      selectionSetsForType.set(id, new CountingSet());
    }
    const selectionSetsForEntity = selectionSetsForType.get(id)!;
    selectionSetsForEntity.add(selectionSet);

    if (selectionSetsForEntity.size === 1) {
      renderedEntities.add(type, id);

      if (fetchPolicy !== 'no-cache') {
        cachedEntities.add(type, id);
      }
    }

    unregister.push(() => {
      selectionSetsForEntity.remove(selectionSet);
      if (selectionSetsForEntity.size === 0) {
        renderedEntities.remove(type, id);
      }
    });
  }

  return () => {
    const temp = unregister;
    unregister = [];
    for (const func of temp) {
      func();
    }
  };
}

/**
 * Collects {@link EntitySelectionSet}s for all entities in {@link data}.
 */
function entitySelectionSetsFromQueryResult(
  document: DocumentNode,
  data: any
): EntitySelectionSet[] {
  document = flattenDocument(document);
  let result: EntitySelectionSet[] = [];
  for (const def of document.definitions) {
    if (def.kind === 'OperationDefinition' && def.operation === 'query') {
      result = result.concat(
        entitySelectionSetsFromSelectionSet(def.selectionSet, data)
      );
    }
  }
  return result;
}

/**
 * Recursively collects {@link EntitySelectionSet}s for all entities in {@link data}.
 */
function entitySelectionSetsFromSelectionSet(
  selectionSet: SelectionSetNode,
  data: any
): EntitySelectionSet[] {
  if (!data) return [];

  if (!entitySelectionSetsFromSelectionSetCache.has(selectionSet)) {
    entitySelectionSetsFromSelectionSetCache.set(selectionSet, new WeakMap());
  }
  const cache = entitySelectionSetsFromSelectionSetCache.get(selectionSet)!;

  if (cache.has(data)) {
    return cache.get(data)!;
  }

  if (!cache.has(data)) {
    let result: EntitySelectionSet[] = [];
    if (Array.isArray(data)) {
      return data
        .map((datum) =>
          entitySelectionSetsFromSelectionSet(selectionSet, datum)
        )
        .reduce((result, refs) => result.concat(refs), []);
    } else {
      if (isEntity(data)) {
        const entity = data as GraphQLEntity;
        result.push({
          type: entity.__typename,
          id: `${entity.id}`,
          selectionSet,
        });
      }
      for (const selection of selectionSet.selections) {
        if (selection.kind === 'Field' && selection.selectionSet) {
          const fieldName = selection.alias?.value || selection.name.value;
          const fieldData = data[fieldName];
          result = result.concat(
            entitySelectionSetsFromSelectionSet(
              selection.selectionSet,
              fieldData
            )
          );
        } else if (
          selection.kind === 'InlineFragment' &&
          selection.typeCondition &&
          isEntity(data)
        ) {
          const entity = data as GraphQLEntity;
          if (entity.__typename === selection.typeCondition.name.value) {
            result = result.concat(
              entitySelectionSetsFromSelectionSet(selection.selectionSet, data)
            );
          }
        }
      }
    }
    cache.set(data, result);
  }

  return cache.get(data)!;
}

/**
 * Returns a new {@link DocumentNode} that doesn't contain fragments.
 *
 * This is safe to do because the spec explicitly forbids cycles.
 * https://spec.graphql.org/June2018/#sec-Fragment-spreads-must-not-form-cycles
 */
function flattenDocument<T extends DocumentNode>(document: T): T {
  if (!flattenDocumentCache.has(document)) {
    const fragmentsByName: { [name: string]: FragmentDefinitionNode } = {};
    for (const def of document.definitions) {
      if (def.kind === 'FragmentDefinition') {
        fragmentsByName[def.name.value] = def;
      }
    }
    const definitions = document.definitions
      .filter((def) => def.kind !== 'FragmentDefinition')
      .map((def) => {
        if (def.kind === 'OperationDefinition') {
          return {
            ...def,
            selectionSet: flattenSelectionSet(
              def.selectionSet,
              fragmentsByName
            ),
          };
        }
        return def;
      });

    flattenDocumentCache.set(document, { ...document, definitions });
  }
  return flattenDocumentCache.get(document) as T;
}

function flattenSelectionSet(
  selectionSet: SelectionSetNode,
  fragments: { [key: string]: FragmentDefinitionNode }
): SelectionSetNode {
  let selections: SelectionNode[] = [];

  for (const selection of selectionSet.selections) {
    switch (selection.kind) {
      case 'Field':
      case 'InlineFragment':
        if (selection.selectionSet) {
          selections.push({
            ...selection,
            selectionSet: flattenSelectionSet(
              selection.selectionSet,
              fragments
            ),
          });
        } else {
          selections.push(selection);
        }
        break;
      case 'FragmentSpread':
        selections = selections.concat(
          flattenSelectionSet(
            fragments[selection.name.value].selectionSet,
            fragments
          ).selections
        );
        break;
      default:
        selections.push(selection);
    }
  }

  return {
    ...selectionSet,
    selections: selections,
  };
}

function annotateSelectionSetWithIds(
  selectionSet: SelectionSetNode,
  onType: string,
  typeMap: TypeMap
): SelectionSetNode {
  const selections = selectionSet.selections.map((selection) => {
    if (
      (selection.kind === 'Field' || selection.kind === 'InlineFragment') &&
      selection.selectionSet
    ) {
      if (selection.kind === 'Field') {
        return {
          ...selection,
          selectionSet: annotateSelectionSetWithIds(
            selection.selectionSet,
            typeMap[onType]![selection.name.value]!,
            typeMap
          ),
        };
      } else if (selection.kind === 'InlineFragment') {
        if (selection.typeCondition) {
          return {
            ...selection,
            selectionSet: annotateSelectionSetWithIds(
              selection.selectionSet,
              selection.typeCondition?.name.value,
              typeMap
            ),
          };
        } else {
          return {
            ...selection,
            selectionSet: annotateSelectionSetWithIds(
              selection.selectionSet,
              onType,
              typeMap
            ),
          };
        }
      }
    }
    return selection;
  });
  if (typeMap[onType]?.['id']) {
    selections.push({
      kind: 'Field',
      name: {
        kind: 'Name',
        value: 'id',
      },
    });
  }
  if (typeMap[onType]?.['updatedAt']) {
    selections.push({
      kind: 'Field',
      name: {
        kind: 'Name',
        value: 'updatedAt',
      },
    });
  }
  return {
    ...selectionSet,
    selections,
  };
}

function selectionsFromSelectionSets(
  selectionSets: SelectionSetNode[]
): SelectionNode[] {
  return selectionSets.reduce(
    (selections, selectionSet) => selections.concat(selectionSet.selections),
    [] as SelectionNode[]
  );
}

/**
 * The main reason this code exists is because the graphene-django-optimizer doesn't seem to
 * optimize properly when the query contains duplicate field selections with sub selections.
 * It only recognizes the sub selections of the first field, even though duplicate field
 * selections is well within the GraphQL spec.
 */
export function mergeSelections(
  selections: Readonly<SelectionNode[]>
): SelectionNode[] {
  const fieldsByKey: Map<string, FieldNode> = new Map();
  const fieldDirectivesByKey: Map<string, DirectiveNode[]> = new Map();
  const fragmentSpreadsByName: Map<string, FragmentSpreadNode> = new Map();
  const inlineFragmentsByType: Map<string, InlineFragmentNode> = new Map();
  const untouchedSelections: SelectionNode[] = [];

  let unprocessedSelections = [...selections];

  while (unprocessedSelections.length > 0) {
    const selection = unprocessedSelections.shift()!;

    switch (selection.kind) {
      case 'Field':
        const key = selection.alias
          ? selection.alias.value
          : selection.name.value;
        if (!fieldsByKey.has(key)) {
          fieldsByKey.set(key, {
            ...selection,
            selectionSet: selection.selectionSet && {
              kind: 'SelectionSet',
              selections: [...selection.selectionSet.selections],
            },
          });
        } else {
          const field = fieldsByKey.get(key)!;

          if (!checkArgumentsEqual(field.arguments, selection.arguments)) {
            const message =
              `Fields "${key}" conflict because they have different arguments. Use ` +
              `different aliases on the fields to fetch both if this was intentional.`;
            console.log(message, field.arguments, selection.arguments);
            throw new Error(message);
          }

          if (field.selectionSet) {
            field.selectionSet.selections = [
              ...field.selectionSet.selections,
              ...(selection.selectionSet?.selections || []),
            ];
          }
        }
        if (selection.directives) {
          if (!fieldDirectivesByKey.has(key)) {
            fieldDirectivesByKey.set(key, [...selection.directives]);
          } else {
            fieldDirectivesByKey.set(key, [
              ...fieldDirectivesByKey.get(key)!,
              ...selection.directives,
            ]);
          }
        }
        break;
      case 'FragmentSpread':
        if (!fragmentSpreadsByName.has(selection.name.value)) {
          fragmentSpreadsByName.set(selection.name.value, selection);
        }
        break;
      case 'InlineFragment':
        if (selection.directives && selection.directives.length > 0) {
          untouchedSelections.push(selection);
        } else {
          if (selection.typeCondition) {
            const typeName = selection.typeCondition.name.value;
            if (!inlineFragmentsByType.has(typeName)) {
              inlineFragmentsByType.set(typeName, {
                ...selection,
                selectionSet: selection.selectionSet && {
                  kind: 'SelectionSet',
                  selections: [...selection.selectionSet.selections],
                },
              });
            } else {
              const inlineFragment = inlineFragmentsByType.get(typeName)!;
              inlineFragment.selectionSet.selections = [
                ...inlineFragment.selectionSet.selections,
                ...selection.selectionSet.selections,
              ];
            }
          } else {
            unprocessedSelections = [
              ...unprocessedSelections,
              ...selection.selectionSet.selections,
            ];
          }
        }
        break;
    }
  }

  for (const [key, field] of Array.from(fieldsByKey.entries())) {
    const directives = distinctDirectives(fieldDirectivesByKey.get(key));
    if (field.selectionSet) {
      const selections = mergeSelections(field.selectionSet.selections);
      fieldsByKey.set(key, {
        ...field,
        selectionSet: {
          kind: 'SelectionSet',
          selections,
        },
        directives,
      });
    } else {
      fieldsByKey.set(key, {
        ...field,
        directives,
      });
    }
  }

  for (const [type, inlineFragment] of Array.from(
    inlineFragmentsByType.entries()
  )) {
    const selections = mergeSelections(inlineFragment.selectionSet.selections);
    inlineFragmentsByType.set(type, {
      ...inlineFragment,
      selectionSet: {
        kind: 'SelectionSet',
        selections,
      },
    });
  }

  return [
    ...Array.from(fieldsByKey.values()),
    ...Array.from(inlineFragmentsByType.values()),
    ...Array.from(fragmentSpreadsByName.values()),
    ...untouchedSelections,
  ];
}

function distinctDirectives(
  directives: DirectiveNode[] | undefined
): DirectiveNode[] | undefined {
  if (!directives) return undefined;

  const directivesByName: Map<string, DirectiveNode> = new Map();
  for (const directive of directives) {
    const name = directive.name.value;
    if (!directivesByName.has(name)) {
      directivesByName.set(name, directive);
    } else {
      if (
        !checkArgumentsEqual(
          directive.arguments,
          directivesByName.get(name)!.arguments
        )
      ) {
        const message = `Directives ${name} conflict because they have with different arguments.`;
        console.log(
          message,
          directive.arguments,
          directivesByName.get(name)!.arguments
        );
        throw new Error(message);
      }
    }
  }
  return Array.from(directivesByName.values());
}

function checkArgumentsEqual(
  a: Readonly<ArgumentNode[]> | undefined,
  b: Readonly<ArgumentNode[]> | undefined
): boolean {
  if ((a?.length || 0) !== (b?.length || 0)) {
    return false;
  }

  if (a && b) {
    if (a.length === 0 && b.length === 0) return true;

    const bArgsByName: Map<string, ArgumentNode> = new Map();
    for (const arg of b) {
      bArgsByName.set(arg.name.value, arg);
    }

    for (let i = 0; i < a.length; i++) {
      const aArg = a[i];
      const bArg = bArgsByName.get(aArg.name.value);
      if (!bArg) return false;
      if (!checkValuesEqual(aArg.value, bArg.value)) return false;
    }
  }

  return true;
}

function checkValuesEqual(a: ValueNode, b: ValueNode): boolean {
  if (a.kind !== b.kind) return false;

  switch (a.kind) {
    case 'BooleanValue':
    case 'EnumValue':
    case 'FloatValue':
    case 'IntValue':
    case 'StringValue':
      return (
        a.value ===
        (
          b as
            | BooleanValueNode
            | EnumValueNode
            | FloatValueNode
            | IntValueNode
            | StringValueNode
        ).value
      );
    case 'NullValue':
      return true;
    case 'Variable':
      return a.name === (b as VariableNode).name;
    case 'ListValue':
      b = b as ListValueNode;
      if (a.values.length !== b.values.length) return false;
      for (let i = 0; i < a.values.length; i++) {
        if (!checkValuesEqual(a.values[i], b.values[i])) {
          return false;
        }
      }
      return true;
    case 'ObjectValue':
      b = b as ObjectValueNode;
      const bFieldsByName: Map<string, ObjectFieldNode> = new Map();
      for (const bField of b.fields) {
        bFieldsByName.set(bField.name.value, bField);
      }

      for (const aField of a.fields) {
        const bField = bFieldsByName.get(aField.name.value);
        if (!bField) return false;
        if (!checkValuesEqual(aField.value, bField.value)) return false;
      }
      return true;
  }
}
