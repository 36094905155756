export interface Environment {
  /** DataDog environment */
  DD_ENV: string;
  /** DataDog backend service name */
  DD_SERVICE: string;
  /** DataDog service version number */
  DD_VERSION: string;
  GOOGLE_API_KEY: string;
  LAUNCHDARKLY_CLIENT_ID: string;
  APP_ENV: AppEnv;
  PUBNUB_SUBSCRIBE_KEY: string;
  PUBNUB_PUBLISH_KEY: string;
  PUBNUB_CHANNEL_PREFIX: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  API_IDENTIFIER: string;
}

export enum AppEnv {
  PROD = 'PROD',
  DEV = 'DEV',
  LOCAL = 'LOCAL',
}

export const environment: Environment = ((window as any).ENV_DATA || {
  // This is for storybook.
  DD_ENV: process.env.DD_ENV,
  GOOGLE_API_KEY: process.env.GOOGLE_API_KEY,
  LAUNCHDARKLY_CLIENT_ID: process.env.LAUNCHDARKLY_CLIENT_ID,
  AUTH0_CLIENT_ID: process.env.AUTH0_CLIENT_ID,
  AUTH0_DOMAIN: process.env.AUTH0_DOMAIN,
  API_IDENTIFIER: process.env.API_IDENTIFIER,
}) as Environment;
