import { datadogRum } from '@datadog/browser-rum';
import axios from 'axios';
import Cookies from 'js-cookie';

const httpClient = axios.create({
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFTOKEN',
});

httpClient.interceptors.request.use(
  (config) => {
    if (config && config.headers) {
      config.headers.Authorization = `Bearer ${Cookies.get('access_token')}`;
    }
    return config;
  },
  (error) => {
      return Promise.reject(error);
  }
);

httpClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error && error.response && error.response.data) {
      let data = error.response.data;
      // Remove account related data from the logs
      if (
        error.config &&
        error.config.url &&
        error.config.url.startsWith('/accounts')
      ) {
        data = JSON.parse(JSON.stringify(data));
        if (data.form && data.form.fields) {
          data.form.fields = {};
        }
      }
      // add the error to DataDog RUM
      datadogRum.addError(`${error} -> ${JSON.stringify(data)}`);
    } else {
      datadogRum.addError(error);
    }
    return Promise.reject(error);
  }
);

export default httpClient;
