import { createContext, useContext } from 'react';
import { CurrentUser } from '#app/currentUser';

export const KeyAnonymousUser = 'anonymous';
export const KeyUnknownUser = 'unknown';

export enum AuthUserType {
  Unknown = 'unknown',
  Anonymous = 'anonymous',
  Staff = 'staff',
  Driver = 'driver',
  CompanyAdmin = 'companyAdmin',
}

/**
 * Authentication context to use for external services (e.g. LaunchDarkly, FullStory, etc)
 */
export type AuthContextExternal = {
  email: string;
  userType: AuthUserType;
  isApproved?: boolean;
  isSuperUser?: boolean;
  isMco?: boolean;
  isSelfDispatch?: boolean;
  /** @deprecated: use other "Is..." attributes */
  group?: string;
};

export type AuthContextResult = {
  initializing: boolean;
  authenticated: boolean;
  anonymous: boolean;
  approved: boolean;
  user: CurrentUser | null;
  userKey: string;
  userFullName: string;
  userType: AuthUserType;
  externalContext?: AuthContextExternal;
};

export const InitializingAuthContext: AuthContextResult = {
  initializing: true,
  authenticated: false,
  anonymous: true,
  approved: false,
  user: null,
  userKey: KeyUnknownUser,
  userFullName: KeyUnknownUser,
  userType: AuthUserType.Unknown,
  externalContext: {
    email: KeyUnknownUser,
    userType: AuthUserType.Unknown,
  },
};

export const AnonymousAuthContext: AuthContextResult = {
  initializing: false,
  authenticated: false,
  anonymous: true,
  approved: false,
  user: null,
  userKey: KeyAnonymousUser,
  userFullName: KeyAnonymousUser,
  userType: AuthUserType.Anonymous,
  externalContext: {
    email: KeyAnonymousUser,
    userType: AuthUserType.Anonymous,
  },
};

/**
 * Authentication context
 */
export const AuthContext =
  createContext<AuthContextResult>(AnonymousAuthContext);

// assign the context's displayName
AuthContext.displayName = 'AuthContext';

/**
 * Hook for accessing the Authentication context.
 * @returns
 */
export const useAuthContext = () => useContext(AuthContext);

export const getAuthContextForUser = (
  user: CurrentUser | null
): AuthContextResult => {
  const authenticated = user?.is_authenticated ?? false;

  if (!authenticated) {
    return AnonymousAuthContext;
  }

  let userType = AuthUserType.Anonymous;
  const userEmail = user?.email ?? 'unknown';
  const isApproved = user?.is_approved ?? false;
  let group = '';
  if (user?.is_staff) {
    group = 'staff';
  } else if (user?.is_mco) {
    group = 'mco';
  } else {
    group = 'ico';
  }

  if (user?.is_staff) {
    userType = AuthUserType.Staff;
  } else if (user?.is_company_admin) {
    userType = AuthUserType.CompanyAdmin;
  } else if (user?.is_trucker) {
    userType = AuthUserType.Driver;
  }

  const externalContext: AuthContextExternal = {
    email: userEmail,
    userType,
    isApproved,
    isSuperUser: user?.is_superuser ?? false,
    isMco: user?.is_mco ?? false,
    isSelfDispatch: user?.is_self_dispatch ?? false,
    group,
  };

  return {
    initializing: false,
    authenticated,
    anonymous: authenticated !== true,
    approved: isApproved,
    user,
    userKey: userEmail,
    userFullName:
      user && user.first_name
        ? `${user?.first_name} ${user?.last_name}`
        : KeyUnknownUser,
    userType,
    externalContext,
  };
};
