import * as React from 'react';
import { Box, createStyles, Hidden, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: 'pink',
      padding: theme.spacing(3),
    },
  })
);

export default function DebugFooter() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Box display="flex">
        <Box mr={3}>
          {process.env.REACT_APP_NAME} v{process.env.REACT_APP_VERSION}
        </Box>
        <Box>
          breakpoint:&nbsp;
          <Hidden only={['sm', 'md', 'lg', 'xl']}>xs</Hidden>
          <Hidden only={['xs', 'md', 'lg', 'xl']}>sm</Hidden>
          <Hidden only={['xs', 'sm', 'lg', 'xl']}>md</Hidden>
          <Hidden only={['xs', 'sm', 'md', 'xl']}>lg</Hidden>
          <Hidden only={['xs', 'sm', 'md', 'lg']}>xl</Hidden>
        </Box>
      </Box>
    </div>
  );
}
