import { Box, Container, Grid, Typography } from '@material-ui/core';
import { Children } from '#app/@types/Children';
import PageBanner from '#app/components/PageBanner';
import { AuthenticatedLayout } from '#app/layouts/AuthenticatedLayout';

interface ReferenceItemProps {
  title: string;
  children: Children;
}

const ReferenceItem: React.FC<ReferenceItemProps> = ({ title, children }) => {
  return (
    <Box>
      <Typography variant="h1">{title}</Typography>
      <p>{children}</p>
    </Box>
  );
};

const PageTitle = 'Reference Information';

export function ReferenceInformation() {
  const url = '/api/invoice/viewinfodoc/bank-info.pdf';
  return (
    <AuthenticatedLayout title={PageTitle}>
      <PageBanner title={PageTitle} />
      <Container maxWidth="md" className="pt-8 px-6 h-full">
        <Grid container className="leading-6">
          <Grid item xs={12} sm={6} className="mb-6 sm:mb-0">
            <ReferenceItem title="Carrier Info">
              <div>DOT# 3353476</div>
              <div>MC# 1072536</div>
              <div>EIN# 85-1488773</div>
              <div>SCAC Code TNHL</div>
            </ReferenceItem>
            <ReferenceItem title="Insurance">
              <div>IOA Insurance Services</div>
              <div>(925) 918-4527</div>
              <div>Broker: Stacey Moen</div>
            </ReferenceItem>
            <ReferenceItem title="Physical Address">
              <div>211 W Pleasant Run Road, Suite 115</div>
              <div>Lancaster TX 75146</div>
            </ReferenceItem>
            <ReferenceItem title="Mailing Address">
              <div>2261 Market Street #4300</div>
              <div>San Francisco CA 94114</div>
            </ReferenceItem>
            <ReferenceItem title="Links to full docs">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://docs.google.com/document/d/1jU9zEgOlzNTmbiSDxazxOQ6aqWteeqpo-w17EcTGkUI/edit?usp=sharing">
                TrueNorth Carrier Info
              </a>
              <div>
                <a target="_blank" rel="noopener noreferrer" href={url}>
                  Additional bank info
                </a>
              </div>
            </ReferenceItem>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReferenceItem title="Bank Info: ACH/Domestic Wires">
              <Typography variant="h2">Beneficiary:</Typography>
              <p>
                <div>TrueNorth Transportation Co.</div>
                <div>1272 York St</div>
                <div>San Francisco, CA 94110</div>
              </p>
              <Typography variant="h2">Bank:</Typography>
              <p>
                <div>Silicon Valley Bank</div>
                <div>3003 Tasman Dr</div>
                <div>Santa Clara, CA 95054</div>
                <div>1-800-774-7390</div>
              </p>
            </ReferenceItem>
            <ReferenceItem title="References">
              <p>
                <div>HTS Logistics LLC</div>
                <div>11554 Davis Creek Court</div>
                <div>Jacksonville, FL 32256</div>
                <div>(877) 874-3164</div>
                <div>carrierpay@hickorytrans.com</div>
              </p>
              <p>
                <div>Roadmaster Specialized</div>
                <div>17235 N 75th Ave Ste D175</div>
                <div>Glendale, AZ 85308</div>
                <div>billing@roadmastergroup.com</div>
              </p>
              <p>
                <div>Patton Logistics LLC</div>
                <div>55 Patton Drive</div>
                <div>Milton, PA 17847</div>
                <div>(570) 847-5504</div>
              </p>
              <p>
                <div>White Trucking (Sherri)</div>
                <div>3721 Green Rd</div>
                <div>Locke, NY 13092</div>
                <div>(315) 497-2025 (phone)</div>
                <div>(315) 497-0200 (fax)</div>
              </p>
            </ReferenceItem>
          </Grid>
        </Grid>
      </Container>
    </AuthenticatedLayout>
  );
}

export default ReferenceInformation;
