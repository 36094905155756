/**
 * @file This is used to override behavior of generated Apollo hooks.
 * The actual override is configured in codegen.yml.
 */

import {
  ApolloCache,
  DefaultContext,
  LazyQueryHookOptions as ApolloLazyQueryHookOptions,
  MutationHookOptions as ApolloMutationHookOptions,
  OperationVariables,
  QueryHookOptions as ApolloQueryHookOptions,
  QueryResult,
  QueryTuple,
  useLazyQuery as useLazyQueryApollo,
  useQuery as useQueryApollo,
  useMutation as useMutationApollo,
  MutationTuple,
  FetchPolicy,
} from '@apollo/client';
import { DocumentNode } from 'graphql';
import { useEffect, useMemo } from 'react';
import { annotateQueryWithIds, registerEntitiesFromQuery } from './utils/liveUpdates/liveEntities';
import { TYPE_MAP } from './graphql-codegen';
import {
  getFetchPolicyOverride,
  registerQuery,
  removeFetchPolicyOverride,
} from './utils/liveUpdates/liveQueries';

// https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
const defaultFetchPolicy: FetchPolicy = 'cache-first';

export type QueryHookOptions<TData = any, TVariables = OperationVariables> = ApolloQueryHookOptions<
  TData,
  TVariables
>;

export type LazyQueryHookOptions<
  TData = any,
  TVariables = OperationVariables
> = ApolloLazyQueryHookOptions<TData, TVariables>;

export type MutationHookOptions<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
> = ApolloMutationHookOptions<TData, TVariables, TContext, TCache>;

export function useQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryResult<TData, TVariables> {
  const variables = options?.variables;
  query = annotateQueryWithIds(query, TYPE_MAP);
  const fetchPolicy = useMemo(
    () => getFetchPolicyOverride(query, options?.fetchPolicy || defaultFetchPolicy),
    [options, query]
  );
  const queryOptions = useMemo(() => ({ query, variables }), [query, variables]);
  const result = useQueryApollo(query, { ...options, fetchPolicy });
  const { data, loading } = result;
  useEffect(() => registerQuery(queryOptions));
  useEffect(() => registerEntitiesFromQuery(query, data, fetchPolicy), [query, data, fetchPolicy]);
  useEffect(() => {
    if (!loading && data !== undefined) {
      removeFetchPolicyOverride(query);
    }
  });
  return result;
}

export function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode,
  options?: QueryHookOptions<TData, TVariables>
): QueryTuple<TData, TVariables> {
  const variables = options?.variables;
  query = annotateQueryWithIds(query, TYPE_MAP);
  const fetchPolicy = useMemo(
    () => getFetchPolicyOverride(query, options?.fetchPolicy || defaultFetchPolicy),
    [options, query]
  );
  const queryOptions = useMemo(() => ({ query, variables }), [query, variables]);
  const tuple = useLazyQueryApollo(query, { ...options, fetchPolicy });
  const [_, { data, loading }] = tuple;
  useEffect(() => registerQuery(queryOptions));
  useEffect(() => registerEntitiesFromQuery(query, data, fetchPolicy), [query, data, fetchPolicy]);
  useEffect(() => {
    if (!loading && data !== undefined) {
      removeFetchPolicyOverride(query);
    }
  });
  return tuple;
}

export function useMutation<
  TData = any,
  TVariables = OperationVariables,
  TContext = DefaultContext,
  TCache extends ApolloCache<any> = ApolloCache<any>
>(
  mutation: DocumentNode,
  options?: MutationHookOptions<TData, TVariables, TContext>
): MutationTuple<TData, TVariables, TContext, TCache> {
  mutation = annotateQueryWithIds(mutation, TYPE_MAP);
  return useMutationApollo(mutation, options);
}
