import { AxiosResponse } from 'axios';
import { History } from 'history';
import httpClient from './httpClient';

export function navigateToHomePage() {
  if (window.location.pathname === '/') {
    return;
  }
  window.location.assign('/');
}

export function navigateToDjangoServerDown() {
  window.location.assign('/server_down');
}

export function navigateToNotApproved(history: History) {
  history.push('/account/pending');
}

export function navigateToNoAccessPage(history: History) {
  history.push('/noaccess');
}

/**
 * Get the authenticated user's information.
 */
export async function getApiAuthenticatedUser(): Promise<AxiosResponse> {
  const response = await httpClient.get(`/api/authenticated-user`);
  return response;
}
