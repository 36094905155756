import { Children } from '#app/@types/Children';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';
import { DesktopAppBar } from './AuthenticatedLayout/DesktopAppBar';
import { ElevationScroll } from './AuthenticatedLayout/ElevationScroll';
import { Footer } from './Footer';

export interface RootLayoutProps {
  /**
   * The content of the component.
   */
  children: Children;
  /**
   * Allows the layout to have flush padding
   */
  noPadding?: boolean;
  /**
   * The title that appears in the browser tab
   */
  title: string;
  /**
   * Title template
   */
  titleTemplate?: string;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    noPadding: {
      padding: theme.spacing(5, 0, 0, 0),
      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(13, 0, 0, 0)
      }
    },
    padding: {
      padding: theme.spacing(9, 3, 3, 3)
    },
    // A flexbox container with a fixed height is necessary for layouts that
    // show a table. Table centric layouts must scroll the table body rather
    // than the page body so that the table header always remains visible. A
    // flexbox container with a fixed height makes it possible to expand the
    // table to the full screen height rather than using a hard-coded table
    // height.
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh'
    },
    body: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1
    }
  });
});

export const RootLayout: React.FC<RootLayoutProps> = ({
  noPadding,
  title,
  titleTemplate = '%s | TrueNorth',
  children
}) => {
  const classes = useStyles();
  const boxClassName = clsx({
    // The background color is applied to the box element rather than the root
    // element because the root element's height is fixed to the screen height.
    [classes.noPadding]: noPadding,
    [classes.padding]: !noPadding,
    [classes.body]: true
  });

  const hideAppBar = Boolean(localStorage.getItem('hideAppBar'));
  const location = useLocation();

  /**
   * hide mobile app bar when appbar query param is "off"
   * currently only used for /my/settlements and /mobile/loadcentral from mobile app
   */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const appBarVisibility = params.get('appbar');
    if (appBarVisibility === 'off') {
      localStorage.setItem('hideAppBar', 'true');
    }
  }, [location.search]);

  return (
    <div className={classes.root}>
      <Helmet defaultTitle="TrueNorth" titleTemplate={titleTemplate}>
        <title>{title}</title>
      </Helmet>

      {!hideAppBar && (
        <header>
          <ElevationScroll>
            <DesktopAppBar />
          </ElevationScroll>
        </header>
      )}

      <main className={classes.body}>
        <Box className={boxClassName}>{children}</Box>
      </main>

      <Footer />
    </div>
  );
};
