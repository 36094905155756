import {
  Divider,
  MenuItem,
  Theme,
  createStyles,
  makeStyles
} from '@material-ui/core';
import { ElementType, MouseEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ChildNavItem } from '#app/layouts/AuthenticatedLayout/DesktopAppBar/NavItem';
import { MyNavLink } from './MyNavLink';

export interface ChildMenuItemProps {
  /**
   * The menu item properties
   */
  item: ChildNavItem;

  /**
   * Callback fired when the menu needs to be closed
   * @param event
   */
  onClose: (event: MouseEvent<EventTarget>) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        textDecoration: 'none'
      }
    }
  })
);

export const ChildMenuItem: React.VFC<ChildMenuItemProps> = ({
  item,
  onClose
}) => {
  const classes = useStyles();
  const renderDivider = () => {
    return item.divider ? <Divider /> : null;
  };

  if (item.onClick) {
    return (
      <>
        {renderDivider()}
        <MenuItem onClick={item.onClick} className={classes.link}>
          {item.title}
        </MenuItem>
      </>
    );
  }

  if (!item.href) {
    return (
      <>
        {renderDivider()}
        <MenuItem
          disabled={item.disabled}
          onClick={onClose}
          className={classes.link}>
          {item.title}
        </MenuItem>
      </>
    );
  }

  let buttonHref: string | undefined = item.href;
  let to = undefined;
  let component: ElementType = item.href ? MyNavLink : 'li';

  if (item.inApp) {
    buttonHref = undefined;
    to = item.href;
  }

  return (
    <>
      {renderDivider()}
      <MenuItem
        className={classes.link}
        component={component}
        disabled={item.disabled}
        exact={item.exact}
        to={to}
        href={buttonHref}
        onClick={onClose}>
        {item.title}
      </MenuItem>
    </>
  );
};

export default ChildMenuItem;
