import { useAuth0 } from '@auth0/auth0-react';
import { Box, Link } from '@material-ui/core';
import Cookies from 'js-cookie';
import { FormatAsEmailLink } from '#app/components/FormatAsEmailLink';
import { UnauthenticatedLayout } from '#app/layouts/UnauthenticatedLayout';

const PageTitle = 'Account Pending';

export const AccountPending: React.VFC = () => {
  const { logout } = useAuth0();
  return (
    <UnauthenticatedLayout title={PageTitle}>
      <Box data-testid="page-account-pending">
        Your account is pending approval. If it has been more than 24 hours,
        please email <FormatAsEmailLink value="support@truenorthfleet.com" />{' '}
        with your details.
      </Box>

      <p>
        <Link
          color="primary"
          onClick={() => {
            Cookies.remove('access_token');
            Cookies.remove('access_token_expiry');
            logout({ logoutParams: { returnTo: window.location.origin } });
          }}
          href="#">
          Sign in with a different account
        </Link>
      </p>
    </UnauthenticatedLayout>
  );
};
