import { useEffect } from 'react';
import yn from 'yn';
import DebugFooter from './DebugFooter';

export function Footer() {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log(
      'process.env.REACT_APP_SHOW_BREAKPOINTS',
      process.env.REACT_APP_SHOW_BREAKPOINTS
    );
  }, []);

  return (
    <footer>
      {yn(process.env.REACT_APP_SHOW_BREAKPOINTS) && <DebugFooter />}
    </footer>
  );
}

export default Footer;
