import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ReactNode } from 'react';
import { Children } from '#app/@types/Children';

export interface Props {
  /**
   * The child components
   */
  children?: Children;
  /**
   * If `true`, the component is loading
   */
  loading?: boolean;
  /**
   * Subtitle of the page, which appears inline and in text.secondary color
   */
  subtitle?: string;
  /**
   * Title of the page
   */
  title?: ReactNode | string;
  /** Class to apply to element */
  className?: string;
  /** Margin bottom */
  mb?: number;
  status?: ReactNode;
}

export const PageBanner: React.FC<Props> = ({
  children,
  loading,
  subtitle,
  title,
  className,
  status,
  mb = 3
}) => {
  return (
    <Box
      display="flex"
      mb={mb}
      justifyContent="space-between"
      alignItems="center"
      className={className}
      data-testid="page-banner">
      {title ? (
        <Box flexGrow={1}>
          <Typography variant="h1" data-testid="page-banner-title">
            {loading ? (
              <Skeleton width={400} />
            ) : (
              <>
                <span data-testid="page-banner-title-text">{title}</span>
                {status}
                {subtitle && (
                  <Box
                    component="span"
                    color="text.secondary"
                    data-testid="page-banner-subtitle">
                    &nbsp;{subtitle}
                  </Box>
                )}
              </>
            )}
          </Typography>
        </Box>
      ) : undefined}
      {children}
    </Box>
  );
};

export default PageBanner;
