import * as LDClient from 'launchdarkly-js-client-sdk';
import { FeatureFlags } from '#app/@types/FeatureFlags';
import {
  AuthContextResult,
  InitializingAuthContext,
  KeyAnonymousUser,
  KeyUnknownUser,
} from '#app/contexts/AuthContext';

/** The active LaunchDarkly context. Used to reduce calling `identify` for the same context. */
export let activeContext: AuthContextResult = InitializingAuthContext;

export const getLDOptionsFromAuthContext = (context: AuthContextResult) => {
  const { user } = context;
  const custom = { ...context.externalContext };
  const defaultValue = context.initializing ? KeyUnknownUser : KeyAnonymousUser;

  // remove email as it's part of ldOptions.
  // NOTE: passing legacy `group` attribute for current LD rules.
  custom.email = undefined;
  const ldIdentifyOptions = {
    key: user?.id.toString() ?? defaultValue,
    email: user?.email ?? defaultValue,
    firstName: user?.first_name,
    lastName: user?.last_name,
    custom,
  };

  return ldIdentifyOptions;
};

/**
 * Set the LaunchDarkly user identity (identify) based upon the AuthContextResult.
 * @param context
 * @param ldClient
 * @returns
 */
export const setLDIdentifyUserFromContext = (
  context: AuthContextResult,
  ldClient?: LDClient.LDClient
) => {
  if (!ldClient) {
    return;
  }

  if (context.userKey === activeContext.userKey) {
    // same context, don't call identify.
    return;
  }

  const options = getLDOptionsFromAuthContext(context);
  // eslint-disable-next-line no-console
  console.log('LD', { options });
  ldClient.identify(options);
};

/**
 * Default flag configuration used when no response from LaunchDarkly.
 */
export const DefaultFlags: LDClient.LDFlagSet = {
  // Add our Feature Flag's default configuration here.
  [FeatureFlags.enableBookNow]: false,
};
