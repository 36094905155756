import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import {
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuList,
  Paper,
  Popper,
  Theme,
  createStyles,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import {
  ElementType,
  KeyboardEvent,
  MouseEvent,
  useMemo,
  useRef,
  useState
} from 'react';
import {
  Link as RouterLink,
  match,
  matchPath,
  useLocation
} from 'react-router-dom';
import { teal } from '#app/themes/MuiFifthWheelThemeProvider/palette';
import { ParentNavItem } from '../NavItem';
import ChildMenuItem from './ChildMenuItem/ChildMenuItem';

export type ParentMenuItemProps = ParentNavItem;

const MenuItemBackgroundColor = teal['800'];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginLeft: theme.spacing(1),
      minWidth: '96px',
      borderRadius: 0,

      '&:hover,&:focus': {
        backgroundColor: MenuItemBackgroundColor
      }
    },
    buttonActive: {
      fontWeight: 'bold'
    },
    iconButton: {
      '&:hover,&:focus': {
        backgroundColor: MenuItemBackgroundColor
      }
    },
    paper: {
      zIndex: 1000
    }
  })
);

/**
 * The parent nav item appears at the top of the page
 *
 * @param props
 * @constructor
 */
export function ParentMenuItem(props: ParentMenuItemProps) {
  const classes = useStyles();
  const {
    id,
    exact,
    disabled,
    href,
    icon,
    items,
    title,
    inApp = false
  } = props;

  const location = useLocation();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  /**
   * Close the dropdown menu
   *
   * @param event
   */
  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const renderedParentNavItem = useMemo(() => {
    const itemId = items ? `activate-${id}` : id;

    /**
     * Icons don't have an active state, so if this is an icon, we can skip the
     * rest of the method.
     */
    if (icon) {
      return (
        <IconButton
          id={itemId}
          aria-controls={open ? id : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          className={classes.iconButton}
          color="inherit"
          onClick={handleToggle}
          ref={anchorRef}>
          {icon}
        </IconButton>
      );
    }

    // Check if the nav item should be considered `active`
    const isActive = ((): boolean => {
      let result: match<{}> | null;

      /**
       * Parents with no children get special treatment
       */
      if (!items) {
        result = matchPath(location.pathname, {
          exact: exact,
          path: href
        });

        return result !== null;
      }

      /**
       * Parents with children require that we iterate over each child to check
       * if the item should be marked as `active`.
       */
      for (let item of items) {
        // Skip this item if no route was provided
        if (!item.href) {
          continue;
        }

        result = matchPath(location.pathname, {
          exact: item.exact,
          path: item.href
        });

        if (result !== null) {
          return true;
        }
      }

      return false;
    })();

    // If the parent has children, render an up or down arrow (depending on open state)
    const endIcon = items ? (
      open ? (
        <ChevronUpIcon width={20} />
      ) : (
        <ChevronDownIcon width={20} />
      )
    ) : undefined;

    let buttonHref = !items ? href : undefined;
    let to = undefined;
    let component: ElementType = 'button';

    if (inApp) {
      component = RouterLink;
      buttonHref = undefined;
      to = href;
    }

    return (
      <Button
        aria-controls={open ? id : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        component={component}
        to={to}
        className={clsx(classes.button, {
          [classes.buttonActive]: isActive
        })}
        id={itemId}
        color="inherit"
        disabled={(!items && !href) || disabled}
        endIcon={endIcon}
        href={buttonHref}
        onClick={items ? handleToggle : undefined}
        ref={anchorRef}>
        {title}
      </Button>
    );
  }, [
    classes,
    disabled,
    exact,
    href,
    location.pathname,
    icon,
    items,
    open,
    title,
    id,
    inApp
  ]);

  return (
    <>
      {renderedParentNavItem}
      {items && (
        <Popper
          disablePortal
          transition
          anchorEl={anchorRef.current}
          open={open}
          placement="bottom-end"
          role={undefined}
          className={classes.paper}>
          {({ TransitionProps, placement: _placement }) => (
            <Grow {...TransitionProps} style={{ transformOrigin: 'right top' }}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id={id}
                    onKeyDown={handleListKeyDown}>
                    {items.map((item, index) => (
                      <ChildMenuItem
                        item={item}
                        key={index}
                        onClose={handleClose}
                      />
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </>
  );
}

export default ParentMenuItem;
