import { ApolloClient, OperationVariables, WatchQueryFetchPolicy } from '@apollo/client';
import { DocumentNode } from 'graphql';
import { DisposeFunc } from './types';

type QueryOptions = { query: DocumentNode; variables?: OperationVariables };

const activeQueriesByRootField = new Map<string, Set<QueryOptions>>();
const rootFieldsByQuery = new Map<DocumentNode, Set<string>>();
const fetchPolicyOverrides = new Map<DocumentNode, WatchQueryFetchPolicy>();
const queriesByRootField = new Map<string, Set<DocumentNode>>();

/**
 * Refreshes any active queries that reference {@link rootFields}. Inactive queries that have cached
 * data are invalidated so that the cache is skipped the next time they are invoked.
 */
export async function refreshRootFields(client: ApolloClient<any>, rootFields: string[]) {
  const refetch = new Set<QueryOptions>();
  const rootFieldsToRefetch = new Set<string>();
  for (const rootField of rootFields) {
    if (queriesByRootField.has(rootField)) {
      for (const query of queriesByRootField.get(rootField)!) {
        fetchPolicyOverrides.set(query, 'network-only');
      }
    }
    if (activeQueriesByRootField.has(rootField)) {
      for (const options of activeQueriesByRootField.get(rootField)!) {
        refetch.add(options);
        removeFetchPolicyOverride(options.query);
        rootFieldsToRefetch.add(rootField);
      }
    }
  }
  if (refetch.size > 0) {
    await Promise.all(
      Array.from(refetch).map((options) =>
        client.query({ ...options, fetchPolicy: 'network-only' })
      )
    );
    console.log(`Refetched root fields: ${Array.from(rootFieldsToRefetch).join(', ')}`);
  }
}

export function getFetchPolicyOverride(
  query: DocumentNode,
  orDefault: WatchQueryFetchPolicy
): WatchQueryFetchPolicy {
  const policy = fetchPolicyOverrides.get(query);
  if (policy === undefined) {
    return orDefault;
  }
  return policy;
}

export function removeFetchPolicyOverride(query: DocumentNode) {
  fetchPolicyOverrides.delete(query);
}

export function registerQuery(options: QueryOptions): DisposeFunc {
  const { query } = options;
  const rootFields = collectRootFields(query);
  for (const field of rootFields) {
    if (!activeQueriesByRootField.has(field)) {
      activeQueriesByRootField.set(field, new Set());
    }
    activeQueriesByRootField.get(field)!.add(options);
  }
  return () => {
    for (const field of rootFields) {
      activeQueriesByRootField.get(field)?.delete(options);
    }
  };
}

function collectRootFields(query: DocumentNode): Set<string> {
  if (!rootFieldsByQuery.has(query)) {
    const rootFields = new Set<string>();
    for (const def of query.definitions) {
      if (def.kind === 'OperationDefinition' && def.operation === 'query') {
        for (const selection of def.selectionSet.selections) {
          if (selection.kind === 'Field') {
            rootFields.add(selection.name.value);
          }
        }
      }
    }
    rootFieldsByQuery.set(query, rootFields);
    for (const field of rootFields) {
      if (!queriesByRootField.has(field)) {
        queriesByRootField.set(field, new Set());
      }
      queriesByRootField.get(field)!.add(query);
    }
  }
  return rootFieldsByQuery.get(query)!;
}
