import { useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { UserRoles } from '#app/@types/Authorization';
import { Children } from '#app/@types/Children';
import {
  navigateToDjangoServerDown,
  navigateToNoAccessPage,
  navigateToNotApproved,
} from '#app/api/auth';
import { useAuthContext } from '#app/contexts/AuthContext/AuthContext';

export interface AuthorizedGuardProps {
  /**
   * The content of the component.
   */
  children: Children;
  /**
   * Only this role can access the page. Default is `staff`.
   */
  requireRole?: keyof typeof UserRoles;
}

/**
 * Provides a guard around whether the user is authorized for a particular role. Additionally the user must be approved.
 */
export const AuthorizedGuard: React.FC<AuthorizedGuardProps> = ({
  children,
  requireRole = UserRoles.staff,
}) => {
  const authContext = useAuthContext();
  const history = useHistory();
  const { user } = authContext;

  const onNotApproved = useCallback(() => {
    navigateToNotApproved(history);
  }, [history]);

  const onNoAccess = useCallback(() => {
    navigateToNoAccessPage(history);
  }, [history]);

  const allowAccess = useMemo(() => {
    if (authContext.initializing) {
      return false;
    }

    if (authContext.anonymous || !authContext.authenticated) {
      return false;
    }

    switch (requireRole) {
      case 'staff':
        return user?.is_staff;
      case 'trucker':
        return user?.is_trucker || user?.is_company_admin || user?.is_staff;
    }
  }, [requireRole, authContext, user]);

  useEffect(() => {
    if (authContext.initializing) {
      return;
    }

    if (!user) {
      navigateToDjangoServerDown();
      return;
    }

    if (!authContext.approved) {
      onNotApproved();
      return;
    }

    if (!allowAccess) {
      onNoAccess();
      return;
    }
  }, [allowAccess, authContext, requireRole, user, onNoAccess, onNotApproved]);

  if (authContext.initializing) {
    return null;
  }

  if (!allowAccess) {
    return null;
  }

  return <>{children}</>;
};
