import { Link, Theme, createStyles, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { ForwardedRef, ReactNode, forwardRef, useMemo } from 'react';
import { Link as RouterLink, matchPath, useLocation } from 'react-router-dom';

export interface MyNavLinkProps {
  /**
   * If `true`, the active URL must match exactly
   */
  exact?: boolean;
  /**
   * The URL to link to
   */
  href: string;
  to: string;
  /**
   * The content of the navigation link
   */
  children: ReactNode;
  /**
   * Class name
   */
  className?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
      color: theme.palette.text.primary,
      '&:hover': {
        textDecoration: 'none'
      }
    },
    activeStyle: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      '&:hover': {
        color: theme.palette.primary.main
      }
    }
  })
);

export const MyNavLink = forwardRef<any, MyNavLinkProps>(
  (props: MyNavLinkProps, ref: ForwardedRef<any>) => {
    const classes = useStyles();
    const { children, exact, href, to, className, ...rest } = props;

    const location = useLocation();

    const isActive = useMemo(() => {
      let result = matchPath(location.pathname, {
        exact: exact,
        path: href
      });
      return result !== null;
    }, [href, exact, location]);

    // As long as we have to maintain two frontends (frontend and tnweb), we can't use NavLink from
    // react-router because the links have to trigger a full page refresh to ensure the proper
    // frontend is loaded.

    const component = to ? RouterLink : 'a';

    return (
      <Link
        ref={ref}
        component={component}
        className={clsx(className, classes.link, {
          [classes.activeStyle]: isActive
        })}
        to={to}
        href={href}
        {...rest}>
        {children}
      </Link>
    );
  }
);

export default MyNavLink;
