import { ApolloCache, ApolloClient, gql } from '@apollo/client';
import { parseJSON } from 'date-fns';
import Pubnub from 'pubnub';
import {
  ENTITY_BULK_LOOKUP_FIELDS_BY_TYPE,
  ENTITY_LOOKUP_FIELDS_BY_TYPE,
  Query,
  TYPE_MAP
} from '../../graphql-codegen';
import {
  Channel,
  EntityUpdateMessage,
  RefreshRootFieldsMessage,
  filterExpressionForEntities
} from '../../pubnub';
import { throttle } from '../throttle';
import {
  TypeMap,
  collectCachedEntities,
  collectCachedEntitiesByType,
  collectQueryForRenderedEntities,
  evictCachedEntity,
  isEntityRendered,
  observeCachedEntities
} from './liveEntities';
import { refreshRootFields as _refreshRootFields } from './liveQueries';

/**
 * In order for an entity to be refreshable, it must have at bare minimum a Query field
 * that can be used to fetch updates. Ideally, it should also have a connection field
 * with an `idIn` argument which can be used to fetch updates for multiple entities at
 * once.
 */
export type RefreshableTypes = keyof typeof ENTITY_LOOKUP_FIELDS_BY_TYPE;

export type RootQueryFields = keyof Query;

// This typing is needed to look up a field dynamically.
const entityLookupFieldsByType: { [type: string]: string[] | undefined } =
  ENTITY_LOOKUP_FIELDS_BY_TYPE;
const entityBulkLookupFieldsByType: { [type: string]: string[] | undefined } =
  ENTITY_BULK_LOOKUP_FIELDS_BY_TYPE;

export function initializeLiveUpdates(
  pubnub: Pubnub,
  client: ApolloClient<any>
) {
  const pendingUpdates: {
    type: string;
    id: string;
    canonicalId: string;
    updatedAt?: string;
    entityBulkLookupFields: string[];
  }[] = [];

  const throttledUpdateForConnectionFields = throttle(() => {
    const entitiesToUpdate = pendingUpdates.filter(
      ({ updatedAt, type, id }) =>
        !updatedAt || checkIfStale(client.cache, updatedAt, { type, id })
    );
    // Clear pendingUpdates array.
    pendingUpdates.splice(0);

    updateEntities(client, entitiesToUpdate);
  }, 1000);

  const listener: Pubnub.ListenerParameters = {
    message(e) {
      // Whenever we receive an update message, refetch that entity.
      if (e.channel === Channel.entityUpdate) {
        const { type, id, batchSize, updatedAt } =
          e.message as EntityUpdateMessage;
        if (!id) {
          return;
        }
        const canonicalId = getCanonicalId(type, id);
        console.log(`Received live update for ${canonicalId}`);

        const entityLookupFields = entityLookupFieldsByType[type];
        const entityBulkLookupFields = entityBulkLookupFieldsByType[type];

        if (entityBulkLookupFields && batchSize > 1) {
          // Whenever there's a large batch of updates coming, throttle refetches
          // to reduce the number of requests we send.
          pendingUpdates.push({
            type,
            id,
            updatedAt,
            entityBulkLookupFields,
            canonicalId
          });
          throttledUpdateForConnectionFields();
        } else if (entityLookupFields) {
          if (
            !updatedAt ||
            checkIfStale(client.cache, updatedAt, { type, id })
          ) {
            updateEntities(client, [
              { type, id, entityLookupFields: entityLookupFields, canonicalId }
            ]);
          }
        }
      } else if (e.channel === Channel.refreshRootFields) {
        const { rootFields } = e.message as RefreshRootFieldsMessage;
        console.log(
          `Received notification to refresh root fields: ${rootFields.join(
            ', '
          )}`
        );
        _refreshRootFields(client, rootFields);
      }
    },

    status(e) {
      if (e.category === Pubnub.CATEGORIES.PNNetworkUpCategory) {
        // If the network connection is lost, reload all entities
        // as soon as it comes back.
        const entities = collectCachedEntities();
        const params = entities
          .filter((e) => !!entityLookupFieldsByType[e.type])
          .map((e) => ({
            ...e,
            entityLookupFields: entityLookupFieldsByType[e.type]!,
            canonicalId: getCanonicalId(e.type, e.id)
          }));
        updateEntities(client, params);
      }
    }
  };

  pubnub.addListener(listener);

  // During development, if the module is hot-reloaded, remove the listener so it
  // isn't added twice.
  if (module.hot) {
    module.hot.dispose((_) => pubnub?.removeListener(listener));
  }

  // TODO(stephen): Pubnub uses a GET request, and so the size of the filter expression is limited
  // by a maximum URI length. It turns out 32363 characters is long enough to reach this limit and
  // get a 414 Request-URI Too Large error. The approach below won't work for someone who views
  // multiple ICOs on the settlements page.
  //
  // Whenever the entities being consumed by the page changes, update the
  // filter expression so we only receive updates for those entities.
  observeCachedEntities((_) => {
    if (pubnub) {
      const entities = collectCachedEntitiesByType();
      const filter = filterExpressionForEntities(entities);
      if (filter && filter.length < 30000) {
        pubnub.setFilterExpression(
          `channel != '${Channel.entityUpdate}' || (${filter})`
        );
      } else {
        pubnub.setFilterExpression('');
      }
    }
  });

  const channels = {
    channels: [
      Channel.entityUpdate,
      Channel.refreshRootFields,
      Channel.settlementProgress
    ]
  };

  console.log('PubNub subscribe:', channels);
  pubnub.subscribe(channels);
}

/**
 * Refetches rendered entities and evicts cached entities that aren't rendered.
 * @param client
 * @param entities
 */
async function updateEntities(
  client: ApolloClient<any>,
  entities: Array<
    | {
        id: string;
        type: string;
        canonicalId: string;
        entityLookupFields: string[];
        entityBulkLookupFields?: undefined;
      }
    | {
        id: string;
        type: string;
        canonicalId: string;
        entityLookupFields?: undefined;
        entityBulkLookupFields: string[];
      }
  >
) {
  for (const { type, id, canonicalId } of entities) {
    if (!isEntityRendered(type, id)) {
      client.cache.evict({ id: canonicalId });
      evictCachedEntity(type, id);
      console.log(
        `Evicted ${canonicalId} from cache because it's not rendered`
      );
    }
  }

  const query = collectQueryForRenderedEntities(entities);
  if (query) {
    await client.query({ query, fetchPolicy: 'no-cache' }).then((result) => {
      for (const { canonicalId } of entities) {
        client.cache.evict({ id: canonicalId, broadcast: false });
      }
      client.writeQuery({ query, data: result.data });
      for (const { canonicalId } of entities) {
        console.log(`Reloaded ${canonicalId} due to live update`);
      }
    });
  }
}

/**
 * Efficiently refetches any active queries that reference the listed entities.
 *
 * Unlike ApolloClient.refetchQueries, this approach works with pagination.
 * https://github.com/apollographql/apollo-client/issues/8652#issuecomment-899617217
 *
 * TODO: refactor to be hook based.
 */
export async function refreshEntities(
  client: ApolloClient<any>,
  pubnub: Pubnub,
  entities: { __typename: RefreshableTypes; id: string | number }[]
): Promise<void> {
  const params = entities.map(({ __typename, id }) => ({
    type: __typename,
    id: `${id}`,
    entityLookupFields: ENTITY_LOOKUP_FIELDS_BY_TYPE[__typename],
    canonicalId: getCanonicalId(__typename, id)
  }));

  await updateEntities(client, params);

  // If the frontend is manually refreshing entities, it's likely because it's too awkward for
  // the backend, so we broadcast to other clients because they will need it too.
  // For example, when updating deductions on the settlements page, there are aggregate fields
  // that are exposed on the AppUserType which have to be refreshed.
  if (pubnub) {
    for (const { __typename, id } of entities) {
      pubnub.publish({
        channel: Channel.entityUpdate,
        meta: { type: __typename, id },
        message: { type: __typename, id, batchSize: entities.length }
      });
    }
  }
}

// TODO: move into apolloClient.
export async function refreshRootFields(
  client: ApolloClient<any>,
  pubnub: Pubnub,
  rootFields: RootQueryFields[]
) {
  if (pubnub) {
    const message: RefreshRootFieldsMessage = {
      rootFields
    };
    pubnub.publish({
      channel: Channel.refreshRootFields,
      message
    });
  }
  await _refreshRootFields(client, rootFields);
}

/**
 * Returns true if the cached entity is stale as of {@link updateTimestamp}.
 * @param cache
 * @param updatedAt
 * @param entity
 * @returns
 */
function checkIfStale(
  cache: ApolloCache<any>,
  updateTimestamp: string,
  entity: {
    type: string;
    id: string;
  }
): boolean {
  const { type, id } = entity;

  // If the entity type defines an updatedAt field, read it from the cache
  // and compare with the updateTimestamp.
  if ((TYPE_MAP as TypeMap)[type]?.['updatedAt']) {
    const entity = cache.readFragment<{ updatedAt?: string }>({
      id: `${type}:${id}`,
      fragment: gql(`
        fragment GetUpdatedAt_${type} on ${type} {
          updatedAt
        }
      `)
    });
    if (entity?.updatedAt) {
      if (parseJSON(entity.updatedAt) >= parseJSON(updateTimestamp)) {
        return false;
      }
    }
  }

  // Default to true otherwise.
  return true;
}

/**
 * A replacement for cache.identify which always returns a string.
 * https://www.apollographql.com/docs/react/api/cache/InMemoryCache/#identify
 * @param type
 * @param id
 * @returns
 */
function getCanonicalId(type: string, id: string | number): string {
  return `${type}:${id}`;
}
