import UnauthenticatedLayout from '#app/layouts/UnauthenticatedLayout';

export function NoAccessPage() {
  return (
    <UnauthenticatedLayout title="No Access">
      <p data-testid="no-access">No Access</p>
    </UnauthenticatedLayout>
  );
}

export default NoAccessPage;
