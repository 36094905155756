import { ChildNavItem, ParentNavItem } from './NavItem';

const contactUsMenuItem: ChildNavItem = {
  id: 'menu-item-contact-us',
  exact: true,
  href: '/contactus',
  inApp: true,
  title: 'Contact Us',
};

const userProfileMenuItem: ChildNavItem = {
  href: '/user/profile',
  inApp: true,
  title: 'Profile',
};

export const navItemsStaff: ParentNavItem[] = [
  {
    id: 'menu-fleet',
    title: 'Fleet',
    items: [
      {
        href: '/fleet',
        inApp: true,
        title: 'Dashboard',
      },
      {
        divider: true,
        href: '/companies',
        inApp: true,
        title: 'Carriers',
      },
      {
        href: '/tractors',
        inApp: true,
        title: 'Tractors',
      },
      {
        href: '/trailers',
        inApp: true,
        title: 'Trailers',
      },
      {
        href: '/devices',
        inApp: true,
        title: 'Devices',
      },
    ],
  },
  {
    id: 'menu-loads',
    title: 'Loads',
    items: [
      {
        href: '/loads',
        inApp: true,
        title: 'Manage Loads',
      },
      {
        exact: true,
        href: '/loadcentral',
        inApp: true,
        title: 'LoadCentral',
      },
    ],
  },
  {
    id: 'menu-item-documents',
    href: '/documents',
    inApp: true,
    title: 'Documents',
  },
  {
    id: 'menu-item-settlements',
    href: '/settlements',
    inApp: true,
    title: 'Settlements',
  },
  {
    id: 'menu-customers',
    href: '/customers',
    inApp: true,
    title: 'Customers',
  },
];

/**
 * To undo the showPendingPaymentsPage flag, delete this const and
 * move the menu-settlements section to navItemsStaff above
 */
export const navItemsStaffPendingPayments: ParentNavItem[] = [
  {
    id: 'menu-fleet',
    title: 'Fleet',
    items: [
      {
        href: '/fleet',
        inApp: true,
        title: 'Dashboard',
      },
      {
        divider: true,
        href: '/companies',
        inApp: true,
        title: 'Carriers',
      },
      {
        href: '/tractors',
        inApp: true,
        title: 'Tractors',
      },
      {
        href: '/trailers',
        inApp: true,
        title: 'Trailers',
      },
      {
        href: '/devices',
        inApp: true,
        title: 'Devices',
      },
    ],
  },
  {
    id: 'menu-loads',
    title: 'Loads',
    items: [
      {
        href: '/loads',
        inApp: true,
        title: 'Manage Loads',
      },
      {
        exact: true,
        inApp: true,
        href: '/loadcentral',
        title: 'LoadCentral',
      },
    ],
  },
  {
    id: 'menu-item-documents',
    href: '/documents',
    inApp: true,
    title: 'Documents',
  },
  {
    id: 'menu-settlements',
    title: 'Settlements',
    items: [
      {
        href: '/settlements',
        inApp: true,
        title: 'Manage Settlements',
      },
      {
        href: '/pending-payments',
        inApp: true,
        title: 'Pending Payments',
      },
    ],
  },
  {
    id: 'menu-customers',
    href: '/customers',
    inApp: true,
    title: 'Customers',
  },
];

export const navItemsTrucker: ParentNavItem[] = [
  {
    id: 'menu-item-load-central',
    exact: true,
    href: '/loadcentral',
    inApp: true,
    title: 'LoadCentral',
  },
  {
    id: 'menu-item-my-loads',
    exact: true,
    href: '/my/settlements',
    inApp: true,
    title: 'Settlements',
  },
  contactUsMenuItem as ParentNavItem,
];

export const profileMenuItemsStaff = (logOut: () => void): ChildNavItem[] => [
  userProfileMenuItem,
  contactUsMenuItem,
  {
    href: '/reference',
    inApp: true,
    title: 'Reference Information',
  },
  {
    divider: true,
    onClick: logOut,
    title: 'Sign Out',
  },
];

export const profileMenuItemsNonStaff = (
  logOut: () => void
): ChildNavItem[] => [
  userProfileMenuItem,
  {
    divider: true,
    onClick: logOut,
    title: 'Sign Out',
  },
];
