import * as FS from '@fullstory/browser';
import { AuthContextResult } from './contexts/AuthContext';

export interface FullStoryAnalytics {
  eventName: FullStoryEvents;
  eventProperties: { [key: string]: any };
}

/**
 * Set the user with FullStory.
 * @param authContext
 */
export const fullstorySetUser = (authContext: AuthContextResult) => {
  // update the FullStory identity
  const fullStoryCustomVars = {
    ...authContext.externalContext,
    // https://help.fullstory.com/hc/en-us/articles/360020828113-FS-identify-Identifying-users#displayname
    displayName: authContext.userFullName,
  } as any;
  // removing legacy group attribute
  fullStoryCustomVars.group = undefined;
  FS.identify(authContext.userKey, fullStoryCustomVars);
};

/*
 * @param eventName The event name must be in PascalCase
 * @param eventProperties A dictionary with key-value pairs, The "keys" must be in camelCase.
 */
export function publishFSCustomEvent(
  eventName: string,
  eventProperties: { [key: string]: any }
) {
  try {
    FS.event(eventName, eventProperties);
  } catch (e) {
    console.log('Failed to publish fullstory custom event', e);
  }
}

export enum FullStoryEvents {
  DFRateConClicked = 'DFRateConClicked',
  DFPODClicked = 'DFPODClicked',
  DFEmailPODClicked = 'DFEmailPODClicked',
  DFPaymentItemClicked = 'DFPaymentItemClicked',
  DFBonusesClicked = 'DFBonusesClicked',
  DFDeductionsClicked = 'DFDeductionsClicked',
  DFReimbursementsClicked = 'DFReimbursementsClicked',
  DFLoadItemClicked = 'DFLoadItemClicked',
  DFPaymentsTabClicked = 'DFPaymentsTabClicked',
  DFLoadsTabClicked = 'DFLoadsClicked',
  DFAdjustmentsTabClicked = 'DFAdjustmentsTabClicked',
  DFHistoryTabClicked = 'DFHistoryTabClicked',
  DFLatestSettlementPDFClicked = 'DFLatestSettlementPDFClicked',
  BCAFromCarrier = 'BCAFromCarrier',
  BCAFromCustomer = 'BCAFromCustomer',
  BCAFromDocument = 'BCAFromDocument',
  AddCarrier = 'AddCarrier',
  DocumentsMenuDownload = 'DocumentsMenuDownload',
  DocumentsMenuDelete = 'DocumentsMenuDelete',
  FacetFilterSearch = 'FacetFilterSearch',
}
