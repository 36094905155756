import { withAuthenticationRequired } from '@auth0/auth0-react';
import * as React from 'react';
import { Route } from 'react-router-dom';
import Loading from '#app/layouts/Loading/Loading';

// @ts-ignore
const ProtectedRoute = ({ component, ...args }) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => <Loading />,
    })}
    {...args}
  />
);

export default ProtectedRoute;
