import { useAuth0 } from '@auth0/auth0-react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import {
  AppBar,
  Theme,
  Toolbar,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import Cookies from 'js-cookie';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useCallback, useMemo } from 'react';
import { FeatureFlagMap } from '#app/@types/FeatureFlags';
import {
  AuthUserType,
  useAuthContext,
} from '#app/contexts/AuthContext/AuthContext';
import { LogoLink } from './LogoLink';
import { ParentMenuItem } from './ParentMenuList';
import {
  navItemsStaff,
  navItemsStaffPendingPayments,
  navItemsTrucker,
  profileMenuItemsNonStaff,
  profileMenuItemsStaff,
} from './navItems';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    grow: {
      flexGrow: 1,
    },
  })
);

export function DesktopAppBar() {
  const { userType, approved = false } = useAuthContext();
  const classes = useStyles();
  const { showPendingPaymentsPage } = useFlags<FeatureFlagMap>();
  const { logout } = useAuth0();

  const logoutAndRemoveCookie = useCallback(() => {
    Cookies.remove('access_token');
    Cookies.remove('access_token_expiry');
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  const navItems = useMemo(() => {
    if (!approved) {
      return [];
    }

    switch (userType) {
      case AuthUserType.Staff:
        if (showPendingPaymentsPage) {
          return navItemsStaffPendingPayments;
        } else {
          return navItemsStaff;
        }
      case AuthUserType.Anonymous:
        return [];
      default:
        return navItemsTrucker;
    }
  }, [userType, approved, showPendingPaymentsPage]);

  const profileNavItems = useMemo(() => {
    return userType === AuthUserType.Staff
      ? profileMenuItemsStaff(logoutAndRemoveCookie)
      : profileMenuItemsNonStaff(logoutAndRemoveCookie);
  }, [userType, logoutAndRemoveCookie]);

  return (
    <AppBar
      color="secondary"
      position="fixed"
      className={classes.appBar}
      data-testid="app-bar"
      data-variant="desktop"
      data-usertype={userType}>
      <Toolbar variant="dense">
        {/* The TrueNorth Logo */}
        <LogoLink />
        {/* Fill the void */}
        <div className={classes.grow} />
        {/* Render each of the parent menu items */}
        {navItems.map((navItem, index) => (
          <ParentMenuItem {...navItem} key={index} />
        ))}
        {/* Render the profile menu */}
        <ParentMenuItem
          id="menu-profile"
          icon={<UserCircleIcon width={24} />}
          items={profileNavItems}
        />
      </Toolbar>
    </AppBar>
  );
}

export default DesktopAppBar;
