import { DisposeFunc, EntityId, EntityType } from './types';

export type EntitySetChangeEvent = {
  added: ReadonlyArray<{ type: EntityType; id: EntityId }>;
  removed: ReadonlyArray<{ type: EntityType; id: EntityId }>;
};

export type EntitySetObserver = (event: EntitySetChangeEvent) => void;

export class ObservableEntitySet {
  private readonly observers: Set<EntitySetObserver> = new Set();
  private readonly entities: Map<EntityType, Set<EntityId>> = new Map();
  private readonly pendingNotifyAdd: Map<EntityType, Set<EntityId>> = new Map();
  private readonly pendingNotifyRemove: Map<EntityType, Set<EntityId>> = new Map();
  private notifyChangeTaskId: number | undefined;

  byType(): ReadonlyMap<EntityType, ReadonlySet<EntityId>> {
    return this.entities;
  }

  asList(): { type: EntityType; id: EntityId }[] {
    const result: { type: EntityType; id: EntityId }[] = [];
    for (const type of this.entities.keys()) {
      const ids = this.entities.get(type)!;
      for (const id of ids) {
        result.push({ type, id });
      }
    }
    return result;
  }

  add(type: EntityType, id: EntityId) {
    if (this.pendingNotifyRemove.has(type) && this.pendingNotifyRemove.get(type)!.has(id)) {
      this.pendingNotifyRemove.get(type)!.delete(id);
    } else {
      if (!this.pendingNotifyAdd.has(type)) {
        this.pendingNotifyAdd.set(type, new Set());
      }
      this.pendingNotifyAdd.get(type)!.add(id);
    }

    if (!this.entities.get(type)) {
      this.entities.set(type, new Set());
    }
    this.entities.get(type)!.add(id);

    if (this.notifyChangeTaskId) {
      cancelAnimationFrame(this.notifyChangeTaskId);
    }
    this.notifyChangeTaskId = requestAnimationFrame(this.notifyChange.bind(this));
  }

  remove(type: EntityType, id: EntityId) {
    if (this.pendingNotifyAdd.has(type) && this.pendingNotifyAdd.get(type)!.has(id)) {
      this.pendingNotifyAdd.get(type)!.delete(id);
    } else {
      if (!this.pendingNotifyRemove.has(type)) {
        this.pendingNotifyRemove.set(type, new Set());
      }
      this.pendingNotifyRemove.get(type)!.add(id);
    }

    this.entities.get(type)?.delete(id);
    if (this.entities.get(type)?.size === 0) {
      this.entities.delete(type);
    }

    if (this.notifyChangeTaskId) {
      cancelAnimationFrame(this.notifyChangeTaskId);
    }
    this.notifyChangeTaskId = requestAnimationFrame(this.notifyChange.bind(this));
  }

  observe(observer: EntitySetObserver): DisposeFunc {
    this.observers.add(observer);

    return () => {
      this.observers.delete(observer);
    };
  }

  private notifyChange() {
    this.notifyChangeTaskId = undefined;

    const added: { type: EntityType; id: EntityId }[] = [];
    const removed: { type: EntityType; id: EntityId }[] = [];

    for (const type of this.pendingNotifyAdd.keys()) {
      for (const id of this.pendingNotifyAdd.get(type)!) {
        added.push({ type, id });
      }
    }

    for (const type of this.pendingNotifyRemove.keys()) {
      for (const id of this.pendingNotifyRemove.get(type)!) {
        removed.push({ type, id });
      }
    }

    this.pendingNotifyAdd.clear();
    this.pendingNotifyRemove.clear();

    if (added.length > 0 || removed.length > 0) {
      const event = { added, removed };
      for (const observer of Array.from(this.observers)) {
        observer(event);
      }
    }
  }
}
