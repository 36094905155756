/**
 * Returns a function that executes {@link callback} at most once every {@link ms}
 * milliseconds.
 * @param callback
 * @param ms
 * @returns
 */
export function throttle(callback: () => void, ms: number): () => void {
  let isOpen = true;
  let timeoutId: number | undefined;
  let callAgain = false;

  return () => {
    if (isOpen) {
      callback();
      isOpen = false;

      clearTimeout(timeoutId);
      setTimeout(() => {
        isOpen = true;

        if (callAgain) {
          callback();
          callAgain = false;
        }
      }, ms);
    } else {
      callAgain = true;
    }
  };
}
