import { UserRoles } from '#app/@types/Authorization';
import { Children } from '#app/@types/Children';
import { AuthorizedGuard } from '#app/guards/AuthorizedGuard';
import { RootLayout } from '../RootLayout';

export interface Props {
  /**
   * The content of the component.
   */
  children: Children;
  /**
   * Allows the layout to have flush padding.
   * @deprecated
   */
  noPadding?: boolean;
  /**
   * Only this role can access the page. Default is staff.
   */
  requireRole?: keyof typeof UserRoles;
  /**
   * The title that appears in the browser tab
   */
  title: string;
  /**
   * Title template
   */
  titleTemplate?: string;
}

/**
 * Ensures user is redirected to login, or has desired role, before showing contents.
 * @param props
 * @returns
 */
export const AuthenticatedLayout: React.FC<Props> = ({
  requireRole,
  children,
  ...otherProps
}) => {
  return (
    <AuthorizedGuard requireRole={requireRole}>
      <RootLayout {...otherProps}>{children}</RootLayout>
    </AuthorizedGuard>
  );
};

export default AuthenticatedLayout;
