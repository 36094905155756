import { Theme, createStyles, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    img: {
      marginTop: theme.spacing(0.5),
    },
  })
);

export function LogoLink() {
  const classes = useStyles();

  return (
    <Link to="/">
      <img
        alt="TrueNorth"
        className={classes.img}
        src={process.env.PUBLIC_URL + '/logos/truenorth-wordmark-white.svg'}
      />
    </Link>
  );
}

export default LogoLink;
